import React, {useRef, useState, useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import ReactToPdf from 'react-to-pdf';

import {mapNumber, mapDate} from 'utils/mapping';

import RightTemplate from 'containers/RightTemplate';

import {Calendar, Button, Spinner, Select} from 'components';

import ChartGradient from './charts/ChartGradient';
import ChartDoughnut from './charts/ChartDoughnut';
import ChartMulti from './charts/ChartMulti';
// eslint-disable-next-line import/no-self-import
import Self from './view';

import './style.scss';

export const Extra = ({reference}) => {
  const {t} = useTranslation();
  const onPrintClick = () => window.print();
  const [isPdfLoading, setPdfLoading] = useState(false);
  reference &&
    (reference.current = {
      ...(reference.current || {}),
      setPdfLoading: state => setPdfLoading(state),
    });
  const onPdfClick = () => {
    setPdfLoading(true);
    reference.current.onPdfClick();
  };
  return (
    <div className="statistics-extra report">
      <div className="name">{t('statistics.extra.title')}</div>
      <Button title={t('statistics.extra.print')} invert onClick={onPrintClick} />
      <Button
        title={t('statistics.extra.pdf')}
        invert
        loading={isPdfLoading}
        onClick={onPdfClick}
      />
    </div>
  );
};

const chartColors = [
  '#4c41ce',
  '#F97468',
  '#1488CC',
  '#9359b0',
  '#d65346',
  '#6fc3fa',
  '#0a923d',
  '#fab43c',
  '#df35ff',
  '#46d41f',
];

const maxValue = array => {
  if (!array?.length) return 0;
  if (typeof array[0] === 'object') {
    return array.reduce((max, item) => Math.max(max, maxValue(item.data)), 0);
  }
  return Math.max(...array);
};

const roundStep = (value, step) => Math.ceil(value / step) * step;

const ChartHeader = ({title, print, isLoading, children}) => {
  title = title.split('\n');
  return (
    <div className="chart-header">
      <div className={classNames('loading', {visible: isLoading})}>
        <Spinner />
      </div>
      <div className="name">
        {title.map((line, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {line.trim()} {index < title.length - 1 && <br />}
          </React.Fragment>
        ))}
      </div>
      <div className="options">{children}</div>
      <div className="print">{print}</div>
    </div>
  );
};

const ContextsChart = ({title, labels, values, onTooltipDraw}) => (
  <div className="item">
    <div className="title">{title}</div>
    <div className="item__inner">
      <div className="img-block">
        <ChartDoughnut
          width={89}
          height={89}
          colors={chartColors}
          labels={labels}
          values={values}
          onTooltipDraw={({value}) => onTooltipDraw(value)}
        />
      </div>
      <div className="info">
        <ul className="list">
          {labels?.map((label, index) => (
            <li key={label}>
              <div style={{backgroundColor: chartColors[index]}} />
              {label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export default ({
  user,
  edges,
  data,
  isLoading,
  range = {},
  forPdf,
  reference,
  _contextsMode,
  _dynamicsMode,
  _dynamicsType,
  onChangeRange,
}) => {
  const {t} = useTranslation();
  const chartTypes = useMemo(
    () => [
      {id: 1, title: t('statistics.types.meditations')},
      {id: 2, title: t('statistics.types.sosPractices')},
      {id: 3, title: t('statistics.types.masterClasses')},
    ],
    [t],
  );

  const secondsToString = useCallback(
    (value, withSeconds = false, short = true) => {
      let seconds = +value;
      if (!seconds) return '0';
      // eslint-disable-next-line no-bitwise
      const hours = ~~(value / 3600);
      seconds -= hours * 3600;
      const minutes = withSeconds ? Math.floor(seconds / 60) : Math.round(seconds / 60);
      seconds -= minutes * 60;
      const result = [];
      hours && result.push(t('statistics.units.h', {count: hours}));
      minutes &&
        result.push(
          t(short ? 'statistics.units.m' : 'statistics.units.minutes', {count: minutes}),
        );
      if (!result.length || withSeconds)
        seconds &&
          result.push(
            t(short ? 'statistics.units.s' : 'statistics.units.seconds', {
              count: seconds,
            }),
          );
      return result.join(' ');
    },
    [t],
  );

  const countToString = useCallback(count => t('statistics.units.times', {count}), [t]);

  isLoading = isLoading || !data;
  const {totals, averages, contexts, dynamics} = data || {};

  const totalsAmount = totals?.amount || 0;
  const totalsStep = 10;
  const totalsMax = roundStep(maxValue(totals?.values), totalsStep) + totalsStep * 2;
  const totalsValues = totals?.values;
  const totalsLabels = totals?.labels;

  const averagesAmount = Math.round((averages?.amount || 0) / 60);
  const averagesStep = 180;
  const averagesMax = roundStep(maxValue(averages?.values), averagesStep) + averagesStep;
  const averagesValues = averages?.values;
  const averagesLabels = averages?.labels;

  const [contextsMode, setContextsMode] = useState(_contextsMode || true);
  const contextsData = contextsMode ? contexts?.durations : contexts?.counts;

  const contextsMeditationsLabels = contextsData && contextsData[1]?.labels;
  const contextsMeditationsValues = contextsData && contextsData[1]?.values;

  const contextsSosPracticesLabels = contextsData && contextsData[2]?.labels;
  const contextsSosPracticesValues = contextsData && contextsData[2]?.values;

  const contextsMasterClassesLabels = contextsData && contextsData[3]?.labels;
  const contextsMasterClassesValues = contextsData && contextsData[3]?.values;

  const onContextsTooltipDraw = contextsMode ? secondsToString : countToString;

  const [dynamicsMode, setDynamicsMode] = useState(_dynamicsMode || true);
  const [dynamicsType, setDynamicsType] = useState(_dynamicsType || 1);
  const dynamicsData = dynamicsMode
    ? dynamics?.durations[dynamicsType]
    : dynamics?.counts[dynamicsType];

  const dynamicsLabels = dynamicsData && dynamicsData?.labels;
  const dynamicsValues = dynamicsData && dynamicsData?.values;
  const dynamicsMaxValue = maxValue(dynamicsValues);
  const dynamicsStep = dynamicsMode
    ? roundStep(dynamicsMaxValue / 5, dynamicsMaxValue > 3600 ? 3600 : 600)
    : roundStep(dynamicsMaxValue / 5, 10 ** (dynamicsMaxValue.toString().length - 1));
  let dynamicsMax = roundStep(maxValue(dynamicsValues), dynamicsStep);
  dynamicsMax === dynamicsMaxValue && (dynamicsMax += dynamicsStep);

  const dynamicsValueDraw = dynamicsMode ? secondsToString : countToString;
  const onDynamicsTickDraw = value => dynamicsValueDraw(value);
  const onDynamicsTooltipDraw = ({value}) => dynamicsValueDraw(value);
  const onDynamicsTooltipSort = ({value: a}, {value: b}) => {
    return a < b ? 1 : a > b ? -1 : 0;
  };

  const pdfRef = useRef();

  const [pdf, setPdf] = useState(false);
  if (!forPdf && reference) {
    reference.current = {
      ...(reference.current || {}),
      onPdfClick: () => setPdf(true),
      onPdfComplete: () => setPdf(false),
    };
  }

  const pdfChildren = ({toPdf}) => {
    setTimeout(toPdf, 100);
    return false;
  };

  const onPdfComplete = () => {
    reference.current.onPdfComplete();
    reference.current.setPdfLoading(false);
  };

  return (
    <RightTemplate
      title={t('statistics.title')}
      className={classNames({'statistics-pdf': forPdf})}
      reference={pdfRef}>
      {forPdf && (
        <ReactToPdf
          targetRef={pdfRef}
          scale={0.8}
          x={10}
          y={10}
          children={pdfChildren}
          filename={`MindSelf Statistics ${mapDate(range.from)}-${mapDate(range.to)}.pdf`}
          onComplete={onPdfComplete}
        />
      )}
      {pdf && (
        <Self
          {...{user, edges, data, range, reference}}
          _contextsMode={contextsMode}
          _dynamicsMode={dynamicsMode}
          _dynamicsType={dynamicsType}
          forPdf
        />
      )}
      <div className="sort-block">
        <Calendar
          range
          minDate={edges[0]}
          maxDate={edges[1]}
          value={range}
          onChange={onChangeRange}
        />
        <div className="company-name">
          {t('statistics.company')} <span>{user.company.name}</span>
        </div>
      </div>

      <div className="stat-block two-column">
        <div className="block-content">
          <ChartHeader title={t('statistics.charts.totals')} isLoading={isLoading} />
          <div className="count-block">
            <div className="count">
              {t('statistics.units.people', {count: mapNumber(totalsAmount)})}
            </div>
          </div>
          <ChartGradient
            width={300}
            height={160}
            min={0}
            max={totalsMax}
            step={totalsStep}
            onTooltipDraw={({value}) => t('statistics.units.people', {count: value})}
            labels={totalsLabels}
            values={totalsValues}
          />
        </div>
        <div className="block-content">
          <ChartHeader title={t('statistics.charts.averages')} isLoading={isLoading} />
          <div className="count-block">
            <div className="count">
              {t('statistics.units.minutes', {count: averagesAmount})}
            </div>
          </div>
          <ChartGradient
            width={300}
            height={160}
            min={0}
            max={averagesMax}
            step={averagesStep}
            onTickDraw={value => secondsToString(value, true, false)}
            onTooltipDraw={({value}) => secondsToString(value, true, false)}
            labels={averagesLabels}
            values={averagesValues}
          />
        </div>
      </div>

      <div className="stat-block">
        <div className="block-content">
          <ChartHeader title={t('statistics.charts.contexts')} isLoading={isLoading}>
            <Button
              title={t('statistics.modes.minutes')}
              disabled={contextsMode}
              invert={contextsMode}
              onClick={() => setContextsMode(true)}
            />
            <Button
              title={t('statistics.modes.times')}
              disabled={!contextsMode}
              invert={!contextsMode}
              onClick={() => setContextsMode(false)}
            />
          </ChartHeader>
          <div className="tabs-content">
            <div className="tabs-content__item">
              <div className="stat-list">
                <ContextsChart
                  title={t('statistics.types.meditations')}
                  labels={contextsMeditationsLabels}
                  values={contextsMeditationsValues}
                  onTooltipDraw={onContextsTooltipDraw}
                />
                <ContextsChart
                  title={t('statistics.types.sosPractices')}
                  labels={contextsSosPracticesLabels}
                  values={contextsSosPracticesValues}
                  onTooltipDraw={onContextsTooltipDraw}
                />
                <ContextsChart
                  title={t('statistics.types.masterClasses')}
                  labels={contextsMasterClassesLabels}
                  values={contextsMasterClassesValues}
                  onTooltipDraw={onContextsTooltipDraw}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="stat-block">
        <div className="block-content">
          <ChartHeader
            title={t('statistics.charts.dynamics')}
            print={chartTypes.find(({id}) => id === dynamicsType).title}
            isLoading={isLoading}>
            <Button
              title={t('statistics.modes.minutes')}
              disabled={dynamicsMode}
              invert={dynamicsMode}
              onClick={() => setDynamicsMode(true)}
            />
            <Button
              title={t('statistics.modes.times')}
              disabled={!dynamicsMode}
              invert={!dynamicsMode}
              onClick={() => setDynamicsMode(false)}
            />
            <Select
              options={chartTypes}
              value={dynamicsType}
              onChange={value => setDynamicsType(+value)}
            />
          </ChartHeader>
          <ChartMulti
            width={200}
            height={forPdf ? 80 : 100}
            min={0}
            max={dynamicsMax}
            step={dynamicsStep}
            colors={chartColors}
            labels={dynamicsLabels}
            values={dynamicsValues}
            onTickDraw={onDynamicsTickDraw}
            onTooltipDraw={onDynamicsTooltipDraw}
            onTooltipSort={onDynamicsTooltipSort}
          />
          <div className="info print">
            <ul className="list row">
              {dynamicsValues?.map(({title}, index) => (
                <li key={title}>
                  <div style={{backgroundColor: chartColors[index]}} />
                  {title}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </RightTemplate>
  );
};

import React from 'react';
import {maxStr, minStr} from '../utils';
import {selectionStatuses} from '../constants';

export default ({
  day,
  days,
  isLastMonth,
  firstDay,
  monthStr,
  todayStr,
  hoveredDates,
  selectedDates,
  selectionStatus,
  dayHeight,
  dayWidth,
  gradients,
  colors,
  fonts,
  onDayClick,
  onDayHover,
}) => {
  const {
    betweenMonthsBorder,
    daysDayText,
    todayBorder,
    hoveredDayBackground,
    selectedHoveredDayBackground,
    selectedDayBackground,
    selectedDayText,
    betweenSelectedDaysBackground,
  } = colors;

  const isFirstDay = day === 1;
  const isTopLine = day <= 7;
  const isLastLine = isLastMonth && day >= days - 6;
  // eslint-disable-next-line no-bitwise
  const line = ~~((day + firstDay - 2) / 7) + 1;
  const isLastDay = day === days;
  const dateStr = `${monthStr}-${day.toString().padStart(2, 0)}`;
  const isToday = dateStr === todayStr;
  const isHovered =
    hoveredDates.length &&
    selectionStatus !== selectionStatuses.FIRST &&
    dateStr >= hoveredDates[0] &&
    dateStr <= hoveredDates[1];
  const isFirstHovered = hoveredDates.length && dateStr === hoveredDates[0];
  const isLastHovered = hoveredDates.length && dateStr === hoveredDates[1];
  const dates =
    selectionStatus === selectionStatuses.FIRST
      ? [...hoveredDates, ...selectedDates]
      : selectedDates;
  const minDate = minStr(...dates);
  const maxDate = maxStr(...dates);
  const isBetween = dateStr >= minDate && dateStr <= maxDate;
  const isFirstInRange = dateStr === minDate;
  const isLastInRange = dateStr === maxDate;
  const className = [`day`];
  isFirstDay && className.push(`week-day-${firstDay}`);

  const fitSize = Math.min(dayHeight, dayWidth);
  const selectedRadius = fitSize / 2;

  const dayStyle = {
    height: dayHeight,
    backgroundImage: gradients[line - 1],
  };
  if (isFirstDay) {
    dayStyle.boxShadow = `0 -1px ${betweenMonthsBorder}, -1px -1px ${betweenMonthsBorder}`;
  } else if (isTopLine) {
    dayStyle.boxShadow = `0 -1px ${betweenMonthsBorder}`;
  } else if (isLastDay) {
    dayStyle.boxShadow = `1px 0 ${betweenMonthsBorder}, 0 1px 0 ${betweenMonthsBorder}`;
  } else if (isLastLine) {
    dayStyle.boxShadow = `0 1px 0 ${betweenMonthsBorder}`;
  }

  const secondTextWrapperStyle = {};
  if (isHovered) {
    secondTextWrapperStyle.backgroundColor = hoveredDayBackground;
  } else if (isBetween) {
    secondTextWrapperStyle.backgroundColor = betweenSelectedDaysBackground;
  }
  if (isFirstInRange || (!isBetween && isFirstHovered)) {
    secondTextWrapperStyle.borderTopLeftRadius = selectedRadius;
    secondTextWrapperStyle.borderBottomLeftRadius = selectedRadius;
  }
  if (isLastInRange || (!isBetween && isLastHovered)) {
    secondTextWrapperStyle.borderTopRightRadius = selectedRadius;
    secondTextWrapperStyle.borderBottomRightRadius = selectedRadius;
  }

  const thirdTextWrapperStyle = {
    borderRadius: selectedRadius,
    fontSize: fonts.daysDay,
  };
  if (isFirstInRange || isLastInRange) {
    thirdTextWrapperStyle.color = selectedDayText;
    if (isHovered) {
      thirdTextWrapperStyle.backgroundColor = selectedHoveredDayBackground;
    } else {
      thirdTextWrapperStyle.backgroundColor = selectedDayBackground;
    }
  } else {
    thirdTextWrapperStyle.color = daysDayText;
  }

  const todayStyle = {
    width: fitSize - 8,
    height: fitSize - 8,
    marginLeft: (fitSize - 8) / -2,
    marginTop: (fitSize - 8) / -2,
    borderColor: todayBorder,
  };

  return (
    <div className={className.join(' ')} style={dayStyle}>
      <div onClick={onDayClick} onMouseEnter={() => onDayHover(dateStr)}>
        <div style={secondTextWrapperStyle}>
          <div style={thirdTextWrapperStyle}>{day}</div>
        </div>
      </div>
      {isToday && <div className="today" style={todayStyle} />}
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

import './style.scss';

const Spinner = ({
  size = 30,
  stroke = 5,
  invert = false,
  color = null,
  style,
  className,
}) => {
  return (
    <svg
      className={classNames('app-spinner', {invert: !!invert}, className)}
      viewBox="0 0 50 50"
      style={{width: size, height: size, ...style}}>
      <circle
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth={stroke}
        style={color && {stroke: color}}
      />
    </svg>
  );
};

export default Spinner;

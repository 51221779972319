import React, {Fragment} from 'react';

const Text = ({text = ''}) => {
  const lines = (text || '').split('\n');
  return (
    <>
      {lines.map((line, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {line}
          {index < lines.length - 1 && <br />}
        </Fragment>
      ))}
    </>
  );
};

export default Text;

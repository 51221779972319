import React, {useEffect, useRef, useState} from 'react';
import {maxStr, minStr} from '../utils';
import {selectionStatuses} from '../constants';

import Year from './year';

import './style.scss';

export default ({
  reference,
  todayStr,
  minDateStr,
  maxDateStr,
  data,
  range,
  yearHeight,
  colors,
  fonts,
  valueFromStr,
  valueToStr,
  onSelect,
  onHover,
}) => {
  const currentYear = +todayStr.substr(0, 4);

  const {monthBackgroundTop, monthBackgroundBottom} = colors;

  const yearsScrollRef = useRef();
  const scrollToValue = () => {
    let yearToScroll = todayStr;
    if (valueFromStr >= minDateStr && valueFromStr <= maxDateStr) {
      yearToScroll = valueFromStr;
    }
    if (yearToScroll >= minDateStr && yearToScroll <= maxDateStr) {
      const index = data.findIndex(({year}) => yearToScroll < year.toString());
      if (index > 1) {
        yearsScrollRef.current.scrollTop = data[index - 1].top - yearHeight;
      }
    }
  };
  useEffect(() => {
    scrollToValue();
  }, []);
  reference && (reference.current = {scrollToValue});

  const yearsRef = useRef();
  const [yearWidth, setYearWidth] = useState(0);
  const updateYearWidth = () => setYearWidth(yearsRef.current.clientWidth / 3);
  useEffect(() => {
    updateYearWidth();
    window.addEventListener('resize', updateYearWidth);
    return () => window.removeEventListener('resize', updateYearWidth);
  }, []);

  const [status, setStatus] = useState(selectionStatuses.CLEAR);
  const [selectedYears, setSelectedYears] = useState([]);
  const [hoveredYear, setHoveredYear] = useState(null);
  const onYearClick = () => {
    // eslint-disable-next-line one-var
    let newSatus, newSelectedYears;
    // eslint-disable-next-line default-case
    switch (status) {
      case selectionStatuses.CLEAR:
      case selectionStatuses.FULL:
        newSatus = range ? selectionStatuses.FIRST : selectionStatuses.FULL;
        newSelectedYears = [hoveredYear, hoveredYear];
        break;
      case selectionStatuses.FIRST:
        // eslint-disable-next-line no-case-declarations
        const dates = [hoveredYear, ...selectedYears];
        newSatus = selectionStatuses.FULL;
        newSelectedYears = [minStr(...dates), maxStr(...dates)];
        break;
    }
    setStatus(newSatus);
    setSelectedYears(newSelectedYears);
    if (!onSelect) return;
    const firstDate = new Date(newSelectedYears[0], 0, 1);
    const lastDate = new Date(newSelectedYears[1], 11, 31);
    onSelect({
      selected: newSatus === selectionStatuses.FULL,
      value: [firstDate, lastDate],
    });
  };
  const onYearHover = setHoveredYear;
  const onYearsLeave = () => status !== selectionStatuses.FIRST && setHoveredYear(null);

  useEffect(() => {
    if (!onHover) return;
    if (hoveredYear) {
      const firstDate = new Date(hoveredYear, 0, 1);
      const lastDate = new Date(hoveredYear, 11, 31);
      onHover([firstDate, lastDate]);
    } else {
      onHover(null);
    }
  }, [hoveredYear]);

  useEffect(() => {
    if (!valueFromStr) {
      setSelectedYears([]);
      setStatus(selectionStatuses.CLEAR);
    } else {
      setSelectedYears([+valueFromStr.substr(0, 4), +valueToStr.substr(0, 4)]);
      setStatus(selectionStatuses.FULL);
    }
  }, [valueFromStr, valueToStr]);

  const rootStyle = {
    backgroundImage: `linear-gradient(${monthBackgroundTop}, ${monthBackgroundBottom})`,
  };

  return (
    <div ref={yearsScrollRef} className="years-scroll" style={rootStyle}>
      <div ref={yearsRef} className="years" onMouseLeave={onYearsLeave}>
        {data.map(({year, top}) => {
          return (
            <Year
              key={year}
              year={year}
              yearHeight={yearHeight}
              yearWidth={yearWidth}
              hoveredYear={hoveredYear}
              selectedYears={selectedYears}
              selectionStatus={status}
              current={currentYear}
              colors={colors}
              fonts={fonts}
              onYearClick={onYearClick}
              onYearHover={onYearHover}
            />
          );
        })}
      </div>
    </div>
  );
};

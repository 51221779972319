import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import RightTemplate from 'containers/RightTemplate';
import Popup from 'containers/Popup';

import {mapDate} from '../../utils/mapping';

import {Spinner, Input, Text, Select, Button} from 'components';

import './style.scss';
import classNames from 'classnames';

export default ({user, tests, history, subscribedMembers, isLoading, onSendTest}) => {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const [testId, setTestId] = useState(-1);
  const selectedTest = tests?.find(test => test.id === testId);

  const [formText, setFormText] = useState({title: '', description: ''});
  useEffect(() => {
    selectedTest &&
      setFormText({
        title: selectedTest.name,
        description: selectedTest.description,
      });
  }, [selectedTest]);
  const hideForm = () => setTestId(-1);

  const [search, setSearch] = useState('');
  let filteredTests = [...(tests || [])];
  const preparedSearch = search.trim().toLowerCase();
  if (preparedSearch !== '') {
    filteredTests = filteredTests.filter(test =>
      test.name.toLowerCase().includes(preparedSearch),
    );
  }
  filteredTests.sort((a, b) => (a.order < b.order ? -1 : 1));

  const [memberId, setMemberId] = useState();
  const membersList = useMemo(() => {
    const result = [];
    Object.values(subscribedMembers).forEach(list =>
      list.forEach(
        member =>
          !result.some(item => item.user_id === member.user_id) && result.push(member),
      ),
    );
    result.sort((a, b) => (a.name < b.name ? -1 : 1));
    setMemberId(result[0]?.id);
    return result;
  }, [subscribedMembers]);

  const [isTestSending, setTestSending] = useState(false);
  const sendTest = async () => {
    setTestSending(true);
    await onSendTest(testId, memberId);
    setTestSending(false);
  };

  const [historyRef, setHistoryRef] = useState(-1);
  const selectedHistory = history?.find(item => item.ref === historyRef);

  const getTestName = id => tests?.find(test => test.id === id)?.name;
  const getTestInterpretation = id => tests?.find(test => test.id === id)?.interpretation;
  const [searchHistory, setSearchHistory] = useState('');
  let filteredHistory = [...(history || [])];
  const preparedSearchHistory = searchHistory.trim().toLowerCase();
  if (preparedSearchHistory !== '') {
    filteredHistory = filteredHistory.filter(
      item =>
        item.member.name.toLowerCase().includes(preparedSearchHistory) ||
        getTestName(item.test_id).toLowerCase().includes(preparedSearchHistory),
    );
  }
  filteredHistory.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));

  const [historyFormData, setHistoryFormData] = useState(null);
  useEffect(() => {
    selectedHistory && setHistoryFormData(selectedHistory);
  }, [selectedHistory]);
  const hideHistoryForm = () => setHistoryRef(-1);

  const [isInterpretationVisible, setInterpretationVisible] = useState(false);

  const [isHistoryTab, setHistoryTab] = useState(false);
  useEffect(() => {
    hideForm();
    hideHistoryForm();
  }, [isHistoryTab]);

  return (
    <RightTemplate title={t('tests.title')}>
      <div className="column-block">
        <div className="company-name">
          {t('tests.company')} <span>{user.company.name}</span>
        </div>
        <div className="left-column">
          <div className="test-tabs">
            <Button
              className="test-tabs-tab"
              title={t('tests.list.button')}
              invert={!isHistoryTab}
              onClick={() => setHistoryTab(false)}
            />
            <Button
              className="test-tabs-tab"
              title={t('tests.results.button')}
              invert={isHistoryTab}
              onClick={() => setHistoryTab(true)}
            />
          </div>
          {!isHistoryTab ? (
            <div className="block-content">
              {isLoading ? (
                <div className="tests-loading">
                  <Spinner size={40} />
                </div>
              ) : (
                <div className="inner">
                  <div className="top">
                    <div className="name">{t('tests.list.title')}</div>
                    <Input
                      className="search-test"
                      placeholder={t('tests.list.find')}
                      icon={require('assets/img/icon__search.png').default}
                      value={search}
                      onChange={setSearch}
                      onEscape={() => setSearch('')}
                    />
                  </div>
                  <div className="tests-list">
                    {filteredTests.map(test => (
                      <div
                        key={test.id}
                        className={classNames({
                          selected: testId === test.id,
                        })}
                        onClick={() => !isTestSending && setTestId(test.id)}>
                        <span>{test.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="block-content">
              {isLoading ? (
                <div className="tests-loading">
                  <Spinner size={40} />
                </div>
              ) : (
                <div className="inner">
                  <div className="top">
                    <div className="name">{t('tests.results.title')}</div>
                    <Input
                      className="search-test"
                      placeholder={t('tests.results.find')}
                      icon={require('assets/img/icon__search.png').default}
                      value={searchHistory}
                      onChange={setSearchHistory}
                      onEscape={() => setSearchHistory('')}
                    />
                  </div>
                  <div className="tests-list">
                    {filteredHistory.map(item => (
                      <div
                        key={item.ref}
                        className={classNames('history', {
                          selected: historyRef === item.ref,
                        })}
                        onClick={() => setHistoryRef(item.ref)}>
                        <div>
                          <span>{item.member.name}</span>
                          <span>{mapDate(item.created_at)}</span>
                        </div>
                        <span>{getTestName(item.test_id)}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className={classNames('right-column', 'test-form', {
            active: testId !== -1,
          })}>
          <div className="block-content">
            <div className="name">{formText.title}</div>
            <div>
              <div className="text">
                <Text text={formText.description} />
                <br />
                <br />
                <p>{t('tests.list.form.text')}</p>
              </div>
              <Select
                className="tests-members-list"
                options={membersList}
                value={memberId}
                onChange={setMemberId}
              />
              <div className="btn-block">
                <Button
                  title={t('tests.list.form.cancel')}
                  invert
                  disabled={isTestSending}
                  onClick={hideForm}
                />
                <Button
                  title={t('tests.list.form.submit')}
                  loading={isTestSending}
                  disabled={!memberId}
                  onClick={sendTest}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames('right-column', 'test-form', {
            active: historyRef !== -1,
          })}>
          <div className="block-content">
            <div className="name">
              {historyFormData?.member.name}
              <span>{mapDate(historyFormData?.created_at)}</span>
            </div>
            <div className="test-history-results-test-name">
              {getTestName(historyFormData?.test_id)}
            </div>
            <ul className="test-history-results">
              {historyFormData?.result.map(({id, title, amount}) => (
                <li key={id}>
                  <div>{title[language]}</div>
                  <div>{amount[language]}</div>
                </li>
              ))}
            </ul>
            <div>
              <div className="btn-block">
                <Button
                  title={t('tests.results.form.cancel')}
                  invert
                  onClick={hideHistoryForm}
                />
                <Button
                  title={t('tests.results.form.submit')}
                  disabled={!getTestInterpretation(historyFormData?.test_id)}
                  onClick={() => setInterpretationVisible(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup visible={isInterpretationVisible} className="test-interpretation-container">
        <div className="test-interpretation-text">
          <Text text={getTestInterpretation(historyFormData?.test_id)} />
        </div>
        <div className="close-interpretation">
          <Button
            title="Закрыть"
            invert
            onClick={() => setInterpretationVisible(false)}
          />
        </div>
      </Popup>
    </RightTemplate>
  );
};

export const isEmpty = value =>
  value === null || value === undefined || value.toString() === '';

export const validateNumber = (string, length = 0) => {
  string = (string ? string.toString() : '').trim();
  let result = /^\d+([,.]\d+)?$/.test(string);
  if (length > 0) result = result && string.length === length;
  return result;
};

export const validateName = (name, isRequired = true) => {
  if (!isRequired && isEmpty(name)) return true;
  const letters = "a-zA-Z\u0400-\u052F\u2DE0-\u2DFF\uA640-\uA69F'";
  const regExp = new RegExp(`^[${letters}]{2,}( [${letters}]{2,})*$`);
  return regExp.test((name || '').trim());
};

export const validatePhone = (phone, isRequired = true) => {
  if (!isRequired && isEmpty(phone)) return true;
  return /^\+?\d{10,20}$/.test((phone || '').trim());
};

export const validatePassword = (password, isRequired = true) => {
  if (!isRequired && isEmpty(password)) return true;
  return /^[a-zA-Z\d_-]{6,}$/.test((password || '').trim());
};

export const validateDate = (date, isRequired = true) => {
  if (!isRequired && isEmpty(date)) return true;
  return /^(\d{2}\.\d{2}\.\d{4}|\d{4}-\d{2}-\d{2})$/.test((date || '').trim());
}

export const validateMail = (mail, isRequired = true) => {
  if (!isRequired && isEmpty(mail)) return true;
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    (mail || '').trim(),
  );
};

import React from 'react';
import {connect} from 'react-redux';

import {getUserData} from 'store/user/selectors';

import View from './view';

const mapStateToProps = state => ({
  user: getUserData(state),
});

const mapActionsToProps = {};

const Wrapper = props => {
  return <View {...props} />;
};

export default connect(mapStateToProps, mapActionsToProps)(Wrapper);

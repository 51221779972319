import api, {setAuthToken} from 'services/api';

import reducers from './reducer';
import userReducers from 'store/user/reducer';
import {loadBalance} from 'store/balance/actions';
import balanceReducers from 'store/balance/reducer';
import membersReducers from 'store/members/reducer';
import statisticsReducers from 'store/statistics/reducer';

export const init = () => async dispatch => {
  try {
    const token = localStorage.getItem('token');
    if (!token) throw new Error();
    setAuthToken(token);
    const data = await api('user').get();
    await dispatch(userReducers.setData(data));
    await dispatch(reducers.setAuthorized(true));
    await dispatch(loadBalance());
  } catch (error) {
    console.log(error);
  } finally {
    await dispatch(reducers.setInited(true));
  }
};

export const clearData = () => dispatch => {
  dispatch(userReducers.clearData());
  dispatch(balanceReducers.clearData());
  dispatch(membersReducers.clearData());
  dispatch(statisticsReducers.clearData());
};

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';

import {mapPrice, mapDate} from 'utils/mapping';

import RightTemplate from 'containers/RightTemplate';

import {Button, Input, Spinner} from 'components';

import './style.scss';

export default ({user, memberCost, balance, onPayClick, isLoading, isGettingPayForm}) => {
  const {t} = useTranslation();
  const [showPayForm, setShowPayForm] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [membersCount, setMembersCount] = useState(10);
  const membersAmount = mapPrice(membersCount * memberCost);
  const actives = balance?.filter(item => item.active) || [];
  const history = balance?.filter(item => !item.active) || [];
  const isBalanceEmpty = !actives?.length;
  const isHistoryEmpty = !history?.length;
  return (
    <RightTemplate title={t('balance.title')}>
      <div className="column-block">
        <div className="company-name">
          {t('balance.company')} <span>{user.company.name}</span>
        </div>
        <div className="left-column">
          <div className="block-content">
            <div className="name">{t('balance.subscriptions.title')}</div>
            <div className="balance-block">
              {isLoading ? (
                <div className="loading">
                  <Spinner size={40} />
                </div>
              ) : (
                <>
                  {isBalanceEmpty ? (
                    <div>{t('balance.subscriptions.empty')}</div>
                  ) : (
                    <div>
                      {actives.map(item => (
                        <div className="active-subscription" key={item.id}>
                          <div className="item">
                            <div className="item-label">
                              {t('balance.subscriptions.fields.date')}
                            </div>
                            <div className="item-info">{mapDate(item.start_date)}</div>
                          </div>
                          <div className="item">
                            <div className="item-label">
                              {t('balance.subscriptions.fields.sum')}
                            </div>
                            <div className="item-info">{mapPrice(item.amount)}</div>
                          </div>
                          <div className="item">
                            <div className="item-label">
                              {t('balance.subscriptions.fields.count')}
                            </div>
                            <div className="item-info">{item.count}</div>
                          </div>
                          <div className="item">
                            <div className="item-label">
                              {t('balance.subscriptions.fields.expire')}
                            </div>
                            <div className="item-info">
                              {t('balance.subscriptions.fields.till')}{' '}
                              {mapDate(item.end_date)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {!!memberCost && (
                    <div className="btn-block">
                      <Button
                        title={t('balance.subscriptions.submit')}
                        className="btn"
                        onClick={() => setShowPayForm(true)}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className={classNames('right-column', 'pay-form', {active: showPayForm})}>
          <div className="block-content">
            <div className="name">{t('balance.subscribe.title')}</div>
            <div className="edit-user">
              <div className="balance-block">
                <div className="item">
                  <div className="item-label">{t('balance.subscribe.fields.price')}</div>
                  <div className="item-info">{`${mapPrice(memberCost)} / ${t(
                    'balance.subscribe.fields.year',
                  )}`}</div>
                </div>
                <div className="item">
                  <div className="item-label">{t('balance.subscribe.fields.count')}</div>
                  <div className="item-info">
                    <Input value={membersCount} numbers onChange={setMembersCount} />
                  </div>
                </div>
                <div className="item">
                  <div className="item-label">{t('balance.subscribe.fields.amount')}</div>
                  <div className="item-info">{membersAmount}</div>
                </div>
                <div className="btn-block">
                  <Button
                    title={t('balance.subscribe.cancel')}
                    className="btn"
                    invert
                    onClick={() => setShowPayForm(false)}
                  />
                  <Button
                    title={t('balance.subscribe.submit')}
                    className="btn"
                    loading={isGettingPayForm}
                    onClick={() => onPayClick(membersCount)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isHistoryEmpty && (
        <div className="history-block">
          <div className="history-btn">
            <div
              className={classNames('block-content', {active: showHistory})}
              id="history-pay"
              onClick={() => setShowHistory(!showHistory)}>
              <div className="icon" />
              {t('balance.history.button')}
            </div>
          </div>
          <div className={classNames('history-info', {visible: showHistory})}>
            <div className="block-content">
              <div className="history-list">
                {history.map(item => (
                  <div className="item" key={item.id}>
                    <div className="date">
                      {mapDate(item.start_date)} – {mapDate(item.end_date)}
                    </div>
                    <div className="sum">{mapPrice(item.amount)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </RightTemplate>
  );
};

import api, {setAuthToken, clearAuthToken} from 'services/api';
import {startLoading, finishLoading} from 'utils/loads';
import {errorMsg, successMsg} from 'utils/messages';
import {getLanguage, getText} from 'utils/localizator';

import reducers from './reducer';
import * as constants from './constants';
import appReducers from 'store/app/reducer';
import {clearData} from 'store/app/actions';

export const signin =
  ({email, password}) =>
  async dispatch => {
    try {
      startLoading(constants.AUTHORIZATION);
      const {token, data} = await api('signin', {email, password}).post();
      setAuthToken(token);
      localStorage.setItem('token', token);
      await dispatch(reducers.setData(data));
      await dispatch(appReducers.setAuthorized(true));
    } catch (error) {
      const msg =
        error.message === 'credentials' && getText('auth.errors.messages.credentials');
      errorMsg(error, msg);
    } finally {
      finishLoading(constants.AUTHORIZATION);
    }
  };

export const signup =
  ({name, email, password}) =>
  async dispatch => {
    try {
      startLoading(constants.REGISTRATION);
      const {token, data} = await api('signup', {name, email, password}).post();
      setAuthToken(token);
      localStorage.setItem('token', token);
      await dispatch(reducers.setData(data));
      await dispatch(appReducers.setAuthorized(true));
    } catch (error) {
      const msg = error.message === 'exist' && getText('auth.errors.messages.existMail');
      errorMsg(error, msg);
    } finally {
      finishLoading(constants.REGISTRATION);
    }
  };

export const restore =
  ({email}) =>
  async () => {
    try {
      startLoading(constants.RESTORING);
      await api('restore', {email, language: getLanguage()}).post();
      successMsg(getText('auth.info.restore'));
    } catch (error) {
      const msg =
        error.message === 'notexist' && getText('auth.errors.messages.wrongMail');
      errorMsg(error, msg);
    } finally {
      finishLoading(constants.RESTORING);
    }
  };

export const signout = () => async dispatch => {
  try {
    startLoading(constants.SIGNOUT);
    clearAuthToken();
    localStorage.removeItem('token');
    await dispatch(appReducers.setAuthorized(false));
    await dispatch(clearData());
  } catch (error) {
    errorMsg(error);
  } finally {
    finishLoading(constants.SIGNOUT);
  }
};

export const update = data => async dispatch => {
  try {
    startLoading(constants.USER_UPDATING);
    await api('user', data).put();
    delete data.password;
    await dispatch(reducers.updateData(data));
  } catch (error) {
    const msg = error.message === 'exist' && getText('auth.errors.messages.existMail');
    errorMsg(error, msg);
  } finally {
    finishLoading(constants.USER_UPDATING);
  }
};

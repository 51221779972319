import {startLoading, finishLoading} from 'utils/loads';
import api from 'services/api';
import {errorMsg, successMsg} from 'utils/messages';
import {getLanguage, getText} from 'utils/localizator';

import reducers from './reducer';
import * as constants from './constants';

export const loadBalance = () => async dispatch => {
  try {
    startLoading(constants.BALANCE_LOADING);
    const {cost, data} = await api('balance').get();
    dispatch(reducers.setCost(cost));
    dispatch(reducers.setData(data));
  } catch (error) {
    errorMsg(error, getText('balance.messages.errors.load'));
  } finally {
    finishLoading(constants.BALANCE_LOADING);
  }
};

export const getPayData = async count => {
  try {
    startLoading(constants.GETTING_PAY_FORM);
    return await api('balance/pay', {
      count,
      language: getLanguage(),
      description: getText('balance.subscribe.description'),
    }).get();
  } catch (error) {
    errorMsg(error);
  } finally {
    finishLoading(constants.GETTING_PAY_FORM);
  }
};

export const checkPayStatus = async id => {
  await api('balance/pay/check', {id}).post();
};

import {startLoading, finishLoading} from 'utils/loads';
import api from 'services/api';
import {successMsg, errorMsg} from 'utils/messages';
import {getLanguage, getText} from 'utils/localizator';

import reducers from './reducer';
import * as constants from './constants';
import {getMembersData} from 'store/members/selectors';
import {loadMembers} from 'store/members/actions';

export const loadTests = () => async (dispatch, getState) => {
  try {
    startLoading(constants.TESTS_LOADING);
    !getMembersData(getState()) && (await dispatch(loadMembers()));
    const {tests, history} = await api('tests', {language: getLanguage()}).get();
    dispatch(reducers.setList(tests));
    dispatch(reducers.setHistory(history));
  } catch (error) {
    errorMsg(error, getText('tests.messages.errors.load'));
  } finally {
    finishLoading(constants.TESTS_LOADING);
  }
};

export const sendTest = async (testId, memberId) => {
  try {
    await api('tests/send', {
      test: testId,
      member: memberId,
      language: getLanguage(),
    }).post();
    successMsg(getText('tests.messages.info.send'));
  } catch (error) {
    errorMsg(error, getText('tests.messages.errors.send'));
  }
};

import {combineReducers} from 'redux';

import {reducer as loads} from './loads/reducer';
import {reducer as app} from './app/reducer';
import {reducer as user} from './user/reducer';
import {reducer as members} from './members/reducer';
import {reducer as statistics} from './statistics/reducer';
import {reducer as tests} from './tests/reducer';
import {reducer as balance} from './balance/reducer';

export default combineReducers({
  loads,
  app,
  user,
  members,
  statistics,
  tests,
  balance,
});

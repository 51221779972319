import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  list: [],
};

let reducers = {
  startLoading: (state, action) => {
    if (!state.list.includes(action.payload)) {
      state.list.push(action.payload);
    }
  },
  finishLoading: (state, action) => {
    if (state.list.includes(action.payload)) {
      state.list = state.list.filter(item => item !== action.payload);
    }
  },
};

const {actions, reducer} = createSlice({
  name: 'loads',
  initialState,
  reducers,
});

reducers = actions;
export default reducers;
export {reducer};

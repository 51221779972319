import {getScrollbarWidth} from './utils';

export const selectionTypes = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

export const weekDays = {
  SUN: 0,
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
};

export const selectionStatuses = {
  CLEAR: 0,
  FIRST: 1,
  FULL: 2,
};

export const scrollBarWidth = getScrollbarWidth();

export const defaultProps = {
  firstWeekDay: weekDays.SUN,
  weekHeight: 40,
  dayHeight: 50,
  monthHeight: 50,
  yearWidth: 60,
  yearHeight: 100,
  selectionType: selectionTypes.DAY,
};

export const defaultColors = {
  background: '#fcfbf9',
  weekBackground: '#3f95ec',
  weekText: '#fff',
  betweenMonthsBorder: '#b8b8b8',
  monthName: '#696871',
  yearName: '#696871',
  daysDayText: '#696871',
  monthsMonthText: '#696871',
  monthsYearText: '#696871',
  monthsYearBackground: '#fcfbf9',
  yearsYearText: '#696871',
  todayBorder: '#ffd100',
  hoveredDayBackground: 'rgba(200, 200, 200, 0.6)',
  selectedDayBackground: 'rgba(0, 126, 255, 0.6)',
  selectedHoveredDayBackground: 'rgba(100, 100, 100, 0.6)',
  selectedDayText: '#fff',
  betweenSelectedDaysBackground: 'rgba(0, 126, 255, 0.2)',
  monthBackgroundTop: '#fff',
  monthBackgroundBottom: '#f3eeea',
};

export const defaultFonts = {
  family: 'sans-serif',
  daysWeek: 16,
  daysDay: 18,
  daysMonth: 60,
  daysYear: 30,
  monthsMonth: 14,
  monthsYear: 20,
  yearsYear: 25,
};

export const defaultDayNames = {
  [weekDays.SUN]: 'Sun',
  [weekDays.MON]: 'Mon',
  [weekDays.TUE]: 'Tue',
  [weekDays.WED]: 'Wed',
  [weekDays.THU]: 'Thu',
  [weekDays.FRI]: 'Fri',
  [weekDays.SAT]: 'Sat',
};

export const defaultMonthNames = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const defaultMonthShortNames = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

import {createSlice} from '@reduxjs/toolkit';

const getCurrentYearEdges = () => {
  const today = new Date();
  return [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)];
};

const initialState = {
  edges: getCurrentYearEdges(),
  range: null,
  data: null,
};

let reducers = {
  setEdges: (state, action) => {
    state.edges = action.payload;
  },
  setRange: (state, action) => {
    state.range = action.payload;
  },
  setData: (state, action) => {
    state.data = action.payload;
  },
  clearData: state => {
    state.edges = getCurrentYearEdges();
    state.data = null;
  },
};

const {actions, reducer} = createSlice({
  name: 'statistics',
  initialState,
  reducers,
});

reducers = actions;
export default reducers;
export {reducer};

// eslint-disable-next-line no-restricted-globals
const isLocal = location.host.indexOf('localhost') > -1;
const apiUrl = isLocal
  ? 'http://localhost:8000/hr/'
  : 'https://mindself.batest.website/hr/';
// const apiUrl = 'https://mindself.batest.website/hr/';

let authToken = false;

export const setAuthToken = newToken => (authToken = newToken);
export const clearAuthToken = () => (authToken = false);

const parseResponse = response => {
  try {
    return JSON.parse(response);
  } catch (e) {
    return response;
  }
};

const parseError = error => {
  if (error[0] === '{') {
    error = JSON.parse(error);
  } else {
    error = {data: error};
  }
  return error;
};

const mimeTypes = {
  gif: 'image/gif',
  bmp: 'image/bmp',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
  svg: 'image/svg+xml',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  webp: 'image/webp',
  avi: 'video/x-msvideo',
  mpeg: 'video/mpeg',
  webm: 'video/webm',
  '3gp': 'video/3gpp',
  mov: 'video/quicktime',
  mp4: 'video/mp4',
  wmv: 'video/x-ms-wmv',
};

const getMimeType = file => {
  let result = 'application/octet-stream';
  if (file.type) {
    result = file.type;
  } else {
    let ext = '';
    file.name && (ext = file.name.replace(/^.*\.([\d\w]+)$/i, '$1'));
    !mimeTypes[ext] && (ext = file.uri.replace(/^.*\.([\d\w]+)$/i, '$1'));
    mimeTypes[ext] && (result = mimeTypes[ext]);
  }
  return result;
};

const buildFormData = (formData, data, parentKey = null) => {
  if (data && typeof data === 'object' && data instanceof File) {
    formData.append(parentKey, data);
  } else if (data && typeof data === 'object' && !(data instanceof Date)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data instanceof Date ? data.toUTCString() : data;
    formData.append(parentKey, value);
  }
};

const checkFileInData = data => {
  let result = false;
  if (data && typeof data === 'object' && data instanceof File) {
    result = true;
  } else if (data && typeof data === 'object' && !(data instanceof Date)) {
    Object.values(data).forEach(value => {
      result = result || checkFileInData(value);
    });
  }
  return result;
};

export default (route: string, data: any = {}, needFormData: boolean = false) => {
  let url = apiUrl + route.replace(/\/*$/, '');
  const isFormData = checkFileInData(data) || needFormData;
  const send = async (method: string): Promise<string | object> => {
    if (method === 'GET') {
      url +=
        '?' + Object.keys(data).reduce((res, key) => (res += `${key}=${data[key]}&`), '');
    }
    const fetchParams: any = {
      method,
      redirect: 'error',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };
    if (!isFormData) {
      fetchParams.headers['Content-Type'] = 'application/json;charset=utf-8';
    }
    if (authToken) {
      fetchParams.headers.Authorization = 'Bearer ' + authToken;
    }
    if (method !== 'GET' && Object.values(data).length) {
      if (isFormData) {
        fetchParams.body = new FormData();
        buildFormData(fetchParams.body, data);
      } else {
        fetchParams.body = JSON.stringify(data);
      }
    }
    const response: Response = await fetch(url, fetchParams);
    if (!response?.status || response.status < 200 || response.status >= 300) {
      const error = parseError(await response.text());
      throw {...error, status: response.status};
    }
    return parseResponse(await response.text());
  };
  return {
    get: () => send('GET'),
    post: () => send('POST'),
    put: () => send('PUT'),
    patch: () => send('PATCH'),
    delete: () => send('DELETE'),
  };
};

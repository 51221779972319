import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {isLoading} from 'utils/loads';

import {getUserData} from 'store/user/selectors';
import {getSubscriptions} from 'store/balance/selectors';
import {getSelectedId, getSelectedMembers, getMembersData} from 'store/members/selectors';
import {
  loadMembers,
  setSelectedMembersId,
  addMember,
  updateMember,
  deleteMember,
  sendFile,
} from 'store/members/actions';
import {
  MEMBERS_LOADING,
  MEMBERS_ADDING,
  MEMBERS_UPDATING,
  MEMBERS_DELETING,
  MEMBERS_SENDING_FILE,
} from 'store/members/constants';

import View from './view';

const mapStateToProps = state => ({
  user: getUserData(state),
  subscriptions: getSubscriptions(state),
  selectedId: getSelectedId(state),
  allMembers: getMembersData(state),
  members: getSelectedMembers(state),
  isLoading: isLoading(MEMBERS_LOADING),
  isAdding: isLoading(MEMBERS_ADDING),
  isUpdating: isLoading(MEMBERS_UPDATING),
  isDeleting: isLoading(MEMBERS_DELETING),
  isSending: isLoading(MEMBERS_SENDING_FILE),
});

const mapActionsToProps = {
  loadMembers,
  onSelectMembersId: setSelectedMembersId,
  onAddMember: addMember,
  onUpdateMember: updateMember,
  onDeleteMember: deleteMember,
  onSendFile: sendFile,
};

const Wrapper = props => {
  const {allMembers, loadMembers, subscriptions, onSelectMembersId} = props;
  useEffect(() => {
    !allMembers && loadMembers();
  }, []);
  useEffect(() => {
    subscriptions.length && onSelectMembersId(subscriptions[0].id);
  }, [subscriptions]);
  return <View {...props} />;
};

export default connect(mapStateToProps, mapActionsToProps)(Wrapper);

import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {isLoading} from 'utils/loads';

import {getUserData} from 'store/user/selectors';
import {getTestsList, getTestsHistory} from 'store/tests/selectors';
import {getMembersData, getSubscribedMembers} from 'store/members/selectors';
import {loadTests, sendTest} from 'store/tests/actions';
import {TESTS_LOADING} from 'store/tests/constants';

import View from './view';

const mapStateToProps = state => ({
  user: getUserData(state),
  tests: getTestsList(state),
  history: getTestsHistory(state),
  subscribedMembers: getSubscribedMembers(state),
  isLoading: isLoading(TESTS_LOADING),
});

const mapActionsToProps = {
  loadTests,
};

let language;

const Wrapper = props => {
  const {tests, loadTests} = props;
  useEffect(() => {
    !tests && loadTests();
  }, []);
  const {i18n} = useTranslation();
  useEffect(() => {
    if (language !== i18n.language) {
      language = i18n.language;
      tests && loadTests();
    }
  }, [i18n.language]);
  return <View {...props} onSendTest={sendTest} />;
};

export default connect(mapStateToProps, mapActionsToProps)(Wrapper);

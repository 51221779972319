import React, {useEffect, useRef, useState} from 'react';
import {dateToStr, getMonthLastDate, maxStr, minStr} from '../utils';
import {selectionStatuses, selectionTypes} from '../constants';

import Month from './month';

import './style.scss';

export default ({
  reference,
  todayStr,
  minDateStr,
  maxDateStr,
  data,
  monthNames,
  range,
  monthHeight,
  yearWidth,
  colors,
  fonts,
  valueFromStr,
  valueToStr,
  onSelect,
  onHover,
}) => {
  const currentMonthStr = todayStr.substr(0, 7);

  const {
    betweenMonthsBorder,
    monthsYearText,
    monthsYearBackground,
    monthBackgroundTop,
    monthBackgroundBottom,
  } = colors;

  const monthsScrollRef = useRef();
  const [monthsScrollTop, setMonthsScrollTop] = useState(0);
  const onMonthsScroll = ({target}) => setMonthsScrollTop(target.scrollTop);
  const monthsScrollHeight = monthsScrollRef.current?.offsetHeight;

  const scrollToValue = () => {
    let monthToScroll = todayStr;
    if (valueFromStr >= minDateStr && valueFromStr <= maxDateStr) {
      monthToScroll = valueFromStr;
    }
    if (monthToScroll >= minDateStr && monthToScroll <= maxDateStr) {
      const index = data.findIndex(({year}) => monthToScroll < year.toString());
      if (index > 1) {
        monthsScrollRef.current.scrollTop = data[index - 1].top - monthHeight;
      }
    }
  };
  useEffect(() => {
    scrollToValue();
  }, []);
  reference && (reference.current = {scrollToValue});

  const monthsRef = useRef();
  const [monthWidth, setMonthWidth] = useState(0);
  const updateMonthWidth = () =>
    setMonthWidth((monthsRef.current.clientWidth - yearWidth) / 6);
  useEffect(() => {
    updateMonthWidth();
    window.addEventListener('resize', updateMonthWidth);
    return () => window.removeEventListener('resize', updateMonthWidth);
  }, []);

  const [status, setStatus] = useState(selectionStatuses.CLEAR);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [hoveredMonth, setHoveredMonth] = useState(null);
  const onMonthClick = () => {
    // eslint-disable-next-line one-var
    let newSatus, newSelectedMonths;
    // eslint-disable-next-line default-case
    switch (status) {
      case selectionStatuses.CLEAR:
      case selectionStatuses.FULL:
        newSatus = range ? selectionStatuses.FIRST : selectionStatuses.FULL;
        newSelectedMonths = [hoveredMonth, hoveredMonth];
        break;
      case selectionStatuses.FIRST:
        // eslint-disable-next-line no-case-declarations
        const dates = [hoveredMonth, ...selectedMonths];
        newSatus = selectionStatuses.FULL;
        newSelectedMonths = [minStr(...dates), maxStr(...dates)];
        break;
    }
    setStatus(newSatus);
    setSelectedMonths(newSelectedMonths);
    if (!onSelect) return;
    const firstDate = new Date(newSelectedMonths[0]);
    const lastDate = getMonthLastDate(newSelectedMonths[1]);
    onSelect({
      selected: newSatus === selectionStatuses.FULL,
      value: [firstDate, lastDate],
    });
  };
  const onMonthHover = setHoveredMonth;
  const onMonthsLeave = () => status !== selectionStatuses.FIRST && setHoveredMonth(null);

  useEffect(() => {
    if (!onHover) return;
    if (hoveredMonth) {
      const firstDate = new Date(hoveredMonth);
      const lastDate = getMonthLastDate(firstDate);
      onHover([firstDate, lastDate]);
    } else {
      onHover(null);
    }
  }, [hoveredMonth]);

  useEffect(() => {
    if (!valueFromStr) {
      setSelectedMonths([]);
      setStatus(selectionStatuses.CLEAR);
    } else {
      setSelectedMonths([valueFromStr.substr(0, 7), valueToStr.substr(0, 7)]);
      setStatus(selectionStatuses.FULL);
    }
  }, [valueToStr, valueFromStr]);

  const lastYear = data[data.length - 1];
  const fullHeight = lastYear ? lastYear.top + lastYear.height : 0;

  return (
    <div
      ref={monthsScrollRef}
      className="months-scroll"
      onScroll={onMonthsScroll}>
      <div
        ref={monthsRef}
        className="months"
        style={{height: fullHeight}}
        onMouseLeave={onMonthsLeave}>
        {data.map(({year, top, height}) => {
          if (
            !monthsScrollHeight ||
            top < monthsScrollTop - height ||
            top > monthsScrollTop + monthsScrollHeight
          )
            return false;
          const yearStyle = {
            top,
            height: monthHeight * 2,
            borderColor: betweenMonthsBorder,
          };
          const yearNameStyle = {
            color: monthsYearText,
            backgroundColor: monthsYearBackground,
            fontSize: fonts.monthsYear,
            minWidth: yearWidth,
            maxWidth: yearWidth,
            borderRightColor: betweenMonthsBorder,
          };
          const monthsStyle = {
            backgroundImage: `linear-gradient(${monthBackgroundTop}, ${monthBackgroundBottom})`,
          };
          return (
            <div key={year} className="year" style={yearStyle}>
              <div className="year-name" style={yearNameStyle}>
                {year}
              </div>
              <div className="year-months" style={monthsStyle}>
                {Array.from({length: 12}, (x, i) => i + 1).map(month => (
                  <Month
                    key={year + month}
                    month={month}
                    monthName={monthNames[month]}
                    year={year}
                    monthHeight={monthHeight}
                    monthWidth={monthWidth}
                    hoveredMonth={hoveredMonth}
                    selectedMonths={selectedMonths}
                    selectionStatus={status}
                    currentStr={currentMonthStr}
                    colors={colors}
                    fonts={fonts}
                    onMonthClick={onMonthClick}
                    onMonthHover={onMonthHover}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import './style.scss';

export default ({user}) => {
  const [isNotifBlockOpened, setNotifBlockOpened] = useState(false);
  return (
    <div className="right-info">
      {/*<div className={classNames('notif-block', {open: isNotifBlockOpened})}>*/}
      {/*  <div className="new" />*/}
      {/*  <div className="icon" onClick={() => setNotifBlockOpened(!isNotifBlockOpened)} />*/}
      {/*  <div className="notif-block__inner">*/}
      {/*    <div className="top">*/}
      {/*      <div className="name">Уведомления</div>*/}
      {/*      <a href="" className="see-all">*/}
      {/*        Смотреть все*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    <div className="notif-list">*/}
      {/*      <div className="item">*/}
      {/*        <div className="name">Элеонора Денисова</div>*/}
      {/*        <div className="action">Прошла тест</div>*/}
      {/*      </div>*/}
      {/*      <div className="item">*/}
      {/*        <div className="name">Элеонора Денисова</div>*/}
      {/*        <div className="action">Прошла тест</div>*/}
      {/*      </div>*/}
      {/*      <div className="item">*/}
      {/*        <div className="name">Элеонора Денисова</div>*/}
      {/*        <div className="action">Прошла тест</div>*/}
      {/*      </div>*/}
      {/*      <div className="item">*/}
      {/*        <div className="name">Элеонора Денисова</div>*/}
      {/*        <div className="action">Прошла тест</div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <Link to="/profile" className="name">
        <div
          className="photo"
          style={{
            backgroundImage: `url(${
              user.avatar || require('assets/img/avatar.png').default
            })`,
          }}
        />
        {user.name}
      </Link>
    </div>
  );
};

import React from 'react';
import {useTranslation} from 'react-i18next';

import './style.scss';

const Footer = () => {
  const {t} = useTranslation();
  return (
    <footer className="main-footer">
      <div className="block">
        <div className="title">{t('footer.contacts.title')}</div>
        <a href="tel:+380508816340" className="icon phone">
          {t('footer.contacts.call')} <br />
          +38 (050) 881 63 40{' '}
        </a>
        <a href="mailto:info@mindself.space" className="icon mail">
          {t('footer.contacts.write')} <br />
          info@mindself.space
        </a>
      </div>
      <div className="block">
        <div className="title">{t('footer.documents.title')}</div>
        <a
          href="https://www.mindself.space/polzovatelskoe-soglashenie/"
          className="menu"
          target="_blank">
          {t('footer.documents.agreement')}
        </a>
        <a
          href="https://www.mindself.space/politika-konfidencialnosti/"
          className="menu"
          target="_blank">
          {t('footer.documents.policy')}
        </a>
      </div>
      <div className="block">
        <div className="title">{t('footer.socials.title')}</div>
        <div className="social-block">
          <a
            href="https://www.facebook.com/MindSelfApp"
            className="social fb"
            target="_blank"
          />
          <a
            href="https://instagram.com/mindself.app"
            className="social ig"
            target="_blank"
          />
        </div>
        <div className="copyright">{t('footer.socials.name')}</div>
        <div className="copyright">{t('footer.socials.copyright')}</div>
      </div>
    </footer>
  );
};

export default Footer;

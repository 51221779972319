import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {isLoading} from 'utils/loads';

import {update, signout} from 'store/user/actions';
import {getUserData, isCompany} from 'store/user/selectors';
import {USER_UPDATING, SIGNOUT} from 'store/user/constants';

import View from './view';

const mapStateToProps = state => ({
  isLoading: isLoading(USER_UPDATING),
  onSigningout: isLoading(SIGNOUT),
  user: getUserData(state),
  isCompany: isCompany(state),
});

const mapActionsToProps = {
  onSubmit: update,
  onSignout: signout,
};

const Wrapper = ({isCompany, ...props}) => {
  const {t} = useTranslation();
  const [initIsCompany] = useState(isCompany);
  const history = useHistory();
  useEffect(() => {
    if (isCompany && !initIsCompany) {
      alert(t('auth.info.successSignup'));
      history.push('/balance');
    }
  }, [isCompany]);
  return <View {...props} />;
};

export default connect(mapStateToProps, mapActionsToProps)(Wrapper);

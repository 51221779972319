import React from 'react';
import * as PropTypes from 'prop-types';

import './style.scss';

const RightTemplate = ({
  title = null,
  description = null,
  reference,
  className = '',
  headerClassName = '',
  children,
}) => {
  return (
    <>
      <div className={`info-block ${headerClassName}`}>
        <div className="title">{title}</div>
        {!!description && <div className="desc">{description}</div>}
      </div>
      <div className={`content ${className}`} ref={reference}>
        {children}
      </div>
    </>
  );
};

RightTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default RightTemplate;

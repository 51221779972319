import React from 'react';
import {connect} from 'react-redux';

import {isLoading} from 'utils/loads';

import {signin, signup, restore} from 'store/user/actions';
import {AUTHORIZATION, REGISTRATION, RESTORING} from 'store/user/constants';

import View from './view';

const mapStateToProps = state => ({
  isLoading: isLoading(AUTHORIZATION, REGISTRATION, RESTORING),
});

const mapActionsToProps = {
  signin,
  signup,
  restore,
};

const Wrapper = ({signin, signup, restore, isLoading}) => {
  return (
    <View onSignin={signin} onSignup={signup} onRestore={restore} isLoading={isLoading} />
  );
};

export default connect(mapStateToProps, mapActionsToProps)(Wrapper);

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  list: null,
  history: null,
};

let reducers = {
  setList: (state, action) => {
    state.list = action.payload;
  },
  setHistory: (state, action) => {
    state.history = action.payload;
  },
  clearData: state => {
    state.list = null;
    state.history = null;
  },
};

const {actions, reducer} = createSlice({
  name: 'tests',
  initialState,
  reducers,
});

reducers = actions;
export default reducers;
export {reducer};

import i18next from 'i18next';

export const getLanguage = () => i18next.language;

export const getText = (path, def = undefined) => {
  const source = i18next.getDataByLanguage(i18next.language)?.translation;
  if (!source) return def ?? path;
  const text = path.split('.').reduce((o, i) => (o || {})[i], source);
  return text ?? def ?? path;
};

import React, {useEffect, useRef} from 'react';
import {Switch, Route, NavLink, Link} from 'react-router-dom';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

import Auth from './auth/index';
import Welcome from './welcome/index';
import Members from './members/index';
import Statistics from './statistics/index';
import Testing from './testing/index';
import Balance from './balance/index';
import Profile from './profile/index';

import User from 'containers/User';
import Footer from 'containers/Footer';

import {Spinner, Select} from 'components';

import './style.scss';

const MenuLink = ({to, title, icon, disabled}) => {
  const Wrapper = !disabled ? NavLink : props => <div {...props} />;
  const wrapperProps = {
    className: classNames('link', {disabled: !!disabled}),
  };
  if (!disabled) {
    wrapperProps.to = to;
    wrapperProps.exact = true;
    wrapperProps.activeClassName = 'link active';
  }
  return (
    <li>
      <Wrapper {...wrapperProps}>
        <div className={`icon ${icon}`} />
        <div className="text">{title}</div>
      </Wrapper>
    </li>
  );
};

export default ({isInited, isAuthorized, isCompany}) => {
  if (!isInited)
    return (
      <div id="initing">
        <Spinner size={50} />
      </div>
    );
  if (!isAuthorized) return <Auth />;

  const extraRef = useRef();
  const routeComponent = Component => props =>
    <Component {...props} reference={extraRef} />;

  const {t, i18n} = useTranslation();
  const langs = Object.keys(i18n.options.resources);

  return (
    <div className="main-container">
      <div className="wrapper wrapper-big">
        <div className="top-block">
          <div className="logo-block">
            <Link to="/" className="logo">
              <img src={require('assets/img/logo.png').default} className="logo-img" />
            </Link>
          </div>
          <div className="top-right-block">
            <Select
              className="langs"
              options={langs.map(id => ({
                id,
                name: i18n.options.resources[id].translation.name,
              }))}
              value={i18n.language}
              onChange={lng => i18n.changeLanguage(lng)}
            />
            <User />
          </div>
        </div>
        <div className="main">
          <div className="sidebar">
            <div className="logo">
              <img src={require('assets/img/logo.png').default} className="logo-img" />
              MindSelf
            </div>
            <ul className="menu-block">
              {isCompany && (
                <>
                  <MenuLink to="/" title={t('menu.members')} icon="icon1" />
                  <MenuLink to="/statistics" title={t('menu.statistics')} icon="icon2" />
                  <MenuLink to="/tests" title={t('menu.tests')} icon="icon3" />
                  <MenuLink to="/balance" title={t('menu.balance')} icon="icon4" />
                </>
              )}
            </ul>
            <div className="bottom-info">
              <img
                src={require('assets/img/logo-big.png').default}
                className="logo-big"
              />
            </div>
            <Switch>
              {isCompany && (
                <Route
                  exact
                  path="/statistics"
                  component={routeComponent(Statistics.extra)}
                />
              )}
            </Switch>
          </div>

          <Switch>
            {isCompany && <Route exact path="/" component={Members} />}
            {isCompany && (
              <Route exact path="/statistics" component={routeComponent(Statistics)} />
            )}
            {isCompany && <Route exact path="/tests" component={Testing} />}
            {isCompany && <Route exact path="/balance" component={Balance} />}
            {!isCompany && <Route exact path="/" component={Welcome} />}
            <Route exact path="/profile" component={Profile} />
            <Route>
              <div
                className="text-center my-5"
                style={{
                  fontSize: 200,
                  fontWeight: 'bold',
                  color: '#dedede',
                  marginLeft: 50,
                }}>
                404
              </div>
            </Route>
          </Switch>
        </div>
      </div>
      <div className="wrapper">
        <Footer />
      </div>
    </div>
  );
};

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: null,
};

let reducers = {
  setData: (state, action) => {
    state.data = action.payload;
  },
  updateData: (state, action) => {
    state.data = {...state.data, ...action.payload};
  },
  clearData: state => {
    state.data = null;
  },
};

const {actions, reducer} = createSlice({
  name: 'user',
  initialState,
  reducers,
});

reducers = actions;
export default reducers;
export {reducer};

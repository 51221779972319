import {useState} from 'react';

const recursionToPaths = (object, path = '') => {
  let result = {};
  Object.keys(object).forEach(key => {
    if (object[key] && typeof object[key] === 'object')
      result = {...result, ...recursionToPaths(object[key], `${path + key}.`)};
    else object[key] !== undefined && (result[path + key] = object[key]);
  });
  return result;
};

const pathsToRecursion = object => {
  const result = {};
  Object.keys(object).forEach(pathKey => {
    const path = pathKey.split('.');
    let link = result;
    path.forEach((key, index) => {
      if (index === path.length - 1) {
        object[pathKey] !== undefined && (link[key] = object[pathKey]);
      } else {
        !link[key] && (link[key] = {});
        link = link[key];
      }
    });
  });
  return result;
};

export const useForm = ({initial = {}, validation, action}) => {
  const [initialData, setInitialData] = useState(initial);
  const [data, setData] = useState(recursionToPaths(initial));
  const changeValue = field => eventOrValue => {
    const value = eventOrValue?.target ? eventOrValue.target.value : eventOrValue;
    setData({...data, [field]: value});
  };
  const [errors, setErrors] = useState({});
  const submit = () => {
    const newErrors = {};
    const validations = recursionToPaths(validation);
    Object.keys(validations).forEach(key => {
      const result = validations[key](data[key], data);
      result !== true && (newErrors[key] = result === false ? true : result);
    });
    setErrors(newErrors);
    !Object.keys(newErrors).length && action && action(pathsToRecursion(data));
  };
  const getMethods = field => ({
    value: data[field] || '',
    error: errors[field],
    onChange: changeValue(field),
  });
  const reset = newData => {
    setErrors({});
    newData && setInitialData(newData);
    setData(recursionToPaths(newData || initialData));
  };
  return {fields: data, input: changeValue, errors, submit, methods: getMethods, reset};
};

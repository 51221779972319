import React from 'react';

import './style.scss';

const Footer = ({visible = false, className, children}) => {
  if (!visible) return null;
  return (
    <div className="app-popup">
      <div>
        <div className={className}>{children}</div>
      </div>
    </div>
  );
};

export default Footer;

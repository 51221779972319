import React from 'react';
import {maxStr, minStr} from '../utils';
import {selectionStatuses} from '../constants';

export default ({
  month,
  monthName,
  currentStr,
  hoveredMonth,
  selectedMonths,
  selectionStatus,
  year,
  monthHeight,
  monthWidth,
  colors,
  fonts,
  onMonthClick,
  onMonthHover,
}) => {
  const {
    monthsMonthText,
    todayBorder,
    hoveredDayBackground,
    selectedHoveredDayBackground,
    selectedDayBackground,
    selectedDayText,
    betweenSelectedDaysBackground,
  } = colors;

  const monthStr = `${year}-${month.toString().padStart(2, 0)}`;
  const isCurrent = monthStr === currentStr;
  const isHovered =
    selectionStatus !== selectionStatuses.FIRST && monthStr === hoveredMonth;
  const months =
    selectionStatus === selectionStatuses.FIRST
      ? [hoveredMonth, ...selectedMonths]
      : selectedMonths;
  const minMonth = minStr(...months);
  const maxMonth = maxStr(...months);
  const isBetween = monthStr >= minMonth && monthStr <= maxMonth;
  const isFirstInRange = monthStr === minMonth;
  const isLastInRange = monthStr === maxMonth;

  const fitSize = Math.min(monthHeight, monthWidth);
  const selectedRadius = fitSize / 2;

  const monthStyle = {
    height: monthHeight,
  };

  const firstTextWrapperStyle = {};
  if (isHovered) {
    firstTextWrapperStyle.backgroundColor = hoveredDayBackground;
  } else if (isBetween) {
    firstTextWrapperStyle.backgroundColor = betweenSelectedDaysBackground;
  }
  if (isFirstInRange || (!isBetween && isHovered)) {
    firstTextWrapperStyle.borderTopLeftRadius = selectedRadius;
    firstTextWrapperStyle.borderBottomLeftRadius = selectedRadius;
  }
  if (isLastInRange || (!isBetween && isHovered)) {
    firstTextWrapperStyle.borderTopRightRadius = selectedRadius;
    firstTextWrapperStyle.borderBottomRightRadius = selectedRadius;
  }

  const secondTextWrapperStyle = {
    borderRadius: selectedRadius,
    fontSize: fonts.monthsMonth,
  };
  if (isFirstInRange || isLastInRange) {
    secondTextWrapperStyle.color = selectedDayText;
    if (isHovered) {
      secondTextWrapperStyle.backgroundColor = selectedHoveredDayBackground;
    } else {
      secondTextWrapperStyle.backgroundColor = selectedDayBackground;
    }
  } else {
    secondTextWrapperStyle.color = monthsMonthText;
  }

  const currentStyle = {
    width: fitSize - 8,
    height: fitSize - 8,
    marginLeft: (fitSize - 8) / -2,
    marginTop: (fitSize - 8) / -2,
    borderColor: todayBorder,
  };

  return (
    <div
      style={monthStyle}
      onClick={onMonthClick}
      onMouseEnter={() => onMonthHover(monthStr)}>
      <div style={firstTextWrapperStyle}>
        <div style={secondTextWrapperStyle}>{monthName}</div>
      </div>
      {isCurrent && <div className="current" style={currentStyle} />}
    </div>
  );
};

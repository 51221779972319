import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useForm} from 'utils/forms';
import {validateMail, validatePassword, validateName} from 'utils/validation';

import {Button, Input} from 'components';

import './style.scss';

export default ({onSignin, onSignup, onRestore, isLoading}) => {
  const {t} = useTranslation();
  const [isSigning, setSigning] = useState(true);
  const [isRestoring, setRestoring] = useState(false);

  const {methods: signinMethods, submit: signinSubmit} = useForm({
    initial: {
      email: process?.env?.REACT_APP_EMAIL,
      password: process?.env?.REACT_APP_PASSWORD,
    },
    validation: {
      email: value => validateMail(value) || t('auth.errors.email'),
      password: value => validatePassword(value) || t('auth.errors.password'),
    },
    action: onSignin,
  });

  const {methods: signupMethods, submit: signupSubmit} = useForm({
    validation: {
      name: value => validateName(value) || t('auth.errors.name'),
      email: value => validateMail(value) || t('auth.errors.email'),
      password: value => validatePassword(value) || t('auth.errors.password'),
      confirm: (value, data) =>
        (!!value && value === data.password) || t('auth.errors.confirm'),
    },
    action: onSignup,
  });

  const {methods: restoreMethods, submit: restoreSubmit} = useForm({
    validation: {
      email: value => validateMail(value) || t('auth.errors.email'),
    },
    action: onRestore,
  });

  return (
    <div className="popup">
      <div className="popup__wrap">
        <div className="popup__block">
          {!isRestoring ? (
            <>
              <div className="left-block">
                <div className="left-block__inner">
                  <div className="logo">
                    <img
                      className="img lazy loaded"
                      data-ll-status="loaded"
                      src={require('assets/img/bg__icon-app.png').default}
                    />
                  </div>
                  <div className="block">
                    <div className="ellipse" />
                    <div className="title">Mindself</div>
                    <div className="text">{t('auth.extra')}</div>
                  </div>
                </div>
              </div>
              <div className="right-block">
                <div className="tabs">
                  <Button
                    title={t('auth.signin.title')}
                    className="tab"
                    invert={isSigning}
                    disabled={isSigning}
                    onClick={() => !isLoading && setSigning(true)}
                  />
                  <Button
                    title={t('auth.signup.title')}
                    className="tab"
                    invert={!isSigning}
                    disabled={!isSigning}
                    onClick={() => !isLoading && setSigning(false)}
                  />
                </div>
                <div className="tabs-content">
                  {isSigning ? (
                    <div className="tabs-content__item">
                      <div className="desc">{t('auth.signin.text')}</div>
                      <div className="popup-form">
                        <div className="input-block">
                          <Input
                            placeholder={t('auth.placeholders.email')}
                            {...signinMethods('email')}
                          />
                        </div>
                        <div className="input-block">
                          <Input
                            placeholder={t('auth.placeholders.password')}
                            security
                            {...signinMethods('password')}
                          />
                        </div>
                        <div className="btn-block">
                          <Button
                            title={t('auth.signin.submit')}
                            loading={isLoading}
                            onClick={signinSubmit}
                          />
                          <span
                            className="link"
                            onClick={() => !isLoading && setRestoring(true)}>
                            {t('auth.signin.forgot')}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="tabs-content__item">
                      <div className="desc">{t('auth.signup.text')}</div>
                      <div className="popup-form">
                        <div className="input-block">
                          <Input
                            placeholder={t('auth.placeholders.name')}
                            {...signupMethods('name')}
                          />
                        </div>
                        <div className="input-block">
                          <Input
                            placeholder={t('auth.placeholders.email')}
                            {...signupMethods('email')}
                          />
                        </div>
                        <div className="input-block">
                          <Input
                            placeholder={t('auth.placeholders.password')}
                            security
                            {...signupMethods('password')}
                          />
                        </div>
                        <div className="input-block">
                          <Input
                            placeholder={t('auth.placeholders.confirm')}
                            security
                            {...signupMethods('confirm')}
                          />
                        </div>
                        <div className="btn-block">
                          <Button
                            title={t('auth.signup.submit')}
                            loading={isLoading}
                            onClick={signupSubmit}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="one-block">
              <div className="one-block__inner">
                <div className="title">{t('auth.reset.title')}</div>
                <div className="text">{t('auth.reset.text')}</div>
                <div className="form-forgot">
                  <div className="input-block">
                    <Input
                      placeholder={t('auth.placeholders.email')}
                      {...restoreMethods('email')}
                    />
                  </div>
                  <div className="btn-block">
                    <Button
                      title={t('auth.reset.submit')}
                      loading={isLoading}
                      onClick={restoreSubmit}
                    />
                    <span className="link cancel" onClick={() => setRestoring(false)}>
                      {t('auth.reset.cancel')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import {Chart} from 'react-chartjs-2';

export const tooltipLine = {
  inside: false,
  beforeDatasetsDraw(chart) {
    if (!this.inside) return;
    chart.ctx.beginPath();
    chart.ctx.strokeStyle = Chart.defaults.borderColor;
    chart.ctx.lineWidth = 1;
    chart.ctx.moveTo(chart.tooltip.caretX, chart.chartArea.top);
    chart.ctx.lineTo(chart.tooltip.caretX, chart.chartArea.bottom);
    chart.ctx.stroke();
  },
  afterEvent(chart, {event}) {
    const {type, x, y} = event;
    if (['mousemove', 'mouseout'].includes(type)) {
      this.inside =
        x >= chart.chartArea.left &&
        x <= chart.chartArea.right &&
        y >= chart.chartArea.top &&
        y <= chart.chartArea.bottom;
    }
  },
};

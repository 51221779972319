export const mapPrice = price => {
  price = price || 0;
  const membersSumInt = Math.trunc(price);
  const membersSumFrac = (price - membersSumInt).toString().substr(2, 2).padEnd(2, '0');
  return `${membersSumInt},${membersSumFrac} грн.`;
};

export const mapDate = (date, format = 'd.m.y') => {
  // eslint-disable-next-line one-var
  let year, month, day, match;
  if (date instanceof Date) {
    year = date.getFullYear().toString();
    month = (date.getMonth() + 1).toString();
    day = date.getDate().toString();
  } else {
    date = (date || '').toString();
    // eslint-disable-next-line no-cond-assign
    if ((match = /(\d{4})-(\d{1,2})-(\d{1,2})/.exec(date))) {
      [, year, month, day] = match;
      // eslint-disable-next-line no-cond-assign
    } else if ((match = /(\d{1,2})\.(\d{1,2})\.(\d{2}|\d{4})/.exec(date))) {
      [, day, month, year] = match;
    }
  }
  if (year) {
    year.length === 2 && (year = `19${year}`);
    return format
      .replace('d', day.padStart(2, 0))
      .replace('m', month.padStart(2, 0))
      .replace('y', year);
  }
  return date;
};

export const apiDate = date => mapDate(date, 'y-m-d');

export const mapNumber = (number, thousandsDivier = true, decimal = true) => {
  if (!number && number !== 0) return '';
  if (typeof number !== 'number') {
    number = parseFloat(number);
  }
  if (!Number.isFinite(number)) return number.toString();
  if (thousandsDivier === true) {
    thousandsDivier = '\u00A0';
  } else if (thousandsDivier === false) {
    thousandsDivier = '';
  }
  const minus = number < 0;
  number = Math.abs(number);
  let [int, dec] = number.toString().split(/[^\d]/g);
  const decimalSeparator = (0.1).toString().match(/[^\d]/g)[0];
  if (int.length > 3) {
    int = int
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .join(thousandsDivier)
      .split('')
      .reverse()
      .join('');
  }
  dec = decimal && dec ? dec : '';
  if (decimal !== true && +decimal >= 1) {
    decimal = +decimal;
    dec = dec.toString().substr(0, decimal).padEnd(decimal, '0');
  }
  dec = dec ? decimalSeparator + dec : '';
  return (minus ? '-' : '') + int + dec;
};

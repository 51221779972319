import React, {useMemo, useEffect, useState} from 'react';
import {defaults, Line} from 'react-chartjs-2';

import {tooltipLine} from './plugins';

const defaultsAnimation = defaults.animation;

export default ({
  width,
  height,
  values,
  labels,
  colors,
  min,
  max,
  step,
  onTickDraw,
  onTooltipDraw,
  onTooltipSort,
}) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const [animation, setAnimation] = useState(false);
  useEffect(() => {
    if (!mounted) return;
    setAnimation({
      ...defaultsAnimation,
      onComplete: () => setAnimation(false),
    });
  }, [values]);

  const data = useMemo(
    () => ({
      labels,
      datasets: values?.map((items, index) => ({
        label: items.title,
        data: items.data,
        fill: false,
        borderColor: [colors[index]],
        borderWidth: 2,
        pointBackgroundColor: colors[index],
        pointBorderColor: 'transparent',
        pointRadius: 0,
        pointHoverBackgroundColor: '#FFC200',
        pointHoverBorderColor: '#F6F5FA',
        pointHoverRadius: 5,
        pointHitRadius: 5,
      })),
    }),
    [values, labels, colors],
  );

  const options = useMemo(
    () => ({
      responsive: true,
      animation,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          position: 'average',
          intersect: false,
          backgroundColor: '#fff',
          titleColor: '#696871',
          titleFont: {
            size: 10,
            family: '"Montserrat", sans-serif',
            style: 'normal',
          },
          bodyColor: '#696871',
          bodyFont: {
            size: 10,
            family: '"Montserrat", sans-serif',
            style: 'normal',
          },
          cornerRadius: 5,
          padding: 12,
          itemSort: (a, b) => {
            if (onTooltipSort)
              return onTooltipSort(
                {
                  label: a.label,
                  value: a.raw,
                  title: a.dataset.label,
                  index: a.datasetIndex,
                },
                {
                  label: b.label,
                  value: b.raw,
                  title: b.dataset.label,
                  index: b.datasetIndex,
                },
              );
          },
          callbacks: {
            label: ({label, raw, formattedValue, datasetIndex, dataset}) => {
              onTooltipDraw &&
                (formattedValue = onTooltipDraw({
                  label,
                  value: raw,
                  title: dataset.label,
                  index: datasetIndex,
                }));
              return `${dataset.label}: ${formattedValue}`;
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: '"Montserrat", sans-serif',
              size: 15,
            },
          },
        },
        y: {
          grid: {
            display: true,
          },
          min,
          max,
          ticks: {
            stepSize: step,
            font: {
              family: '"Montserrat", sans-serif',
              size: 15,
            },
            callback: onTickDraw,
          },
        },
      },
    }),
    [min, max, step, onTickDraw, animation],
  );

  return (
    <Line
      type="line"
      data={data}
      options={options}
      plugins={[tooltipLine]}
      width={width}
      height={height}
    />
  );
};

import React, {useMemo} from 'react';
import classNames from 'classnames';

import './style.scss';

const Input = ({
  placeholder,
  className,
  inputClassName = '',
  errorClassName = '',
  icon,
  numbers,
  security,
  disabled,
  value,
  error,
  onChange,
  onEscape,
  style,
  inputStyle,
  errorStyle,
}) => {
  const needErrorText = typeof error === 'string' && error.length;
  const handleKeyPress = event => {
    numbers && (event.charCode < 48 || event.charCode > 57) && event.preventDefault();
  };
  const handleKeyDown = event => {
    event.keyCode === 27 && onEscape && onEscape();
  };
  const preparedInputStyle = useMemo(
    () => ({
      ...(inputStyle || {}),
      ...(icon
        ? {
            backgroundImage: `url(${icon})`,
          }
        : {}),
    }),
    [inputStyle, icon],
  );
  return (
    <div
      className={classNames('app-input-container', {error: !!error}, className)}
      style={style}>
      <input
        type={security ? 'password' : 'text'}
        className={classNames(inputClassName, {icon: !!icon})}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        style={preparedInputStyle}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        onChange={event => onChange && onChange(event.target.value)}
      />
      {needErrorText && (
        <div className={errorClassName} style={errorStyle}>
          {error}
        </div>
      )}
    </div>
  );
};

export default Input;

import store from '../store/index';
import {getLoading} from '../store/loads/selectors';
import * as loadsActions from '../store/loads/actions';

export const startLoading = type => store.dispatch(loadsActions.startLoading(type));

export const finishLoading = type => store.dispatch(loadsActions.finishLoading(type));

export const isLoading = (...types) => getLoading(store.getState(), types);

export const createLoadings =
  type =>
  (needLoading = true) =>
    needLoading ? startLoading(type) : finishLoading(type);

export const mapWeekDay = (indexFromSunday, indexFirstWeekDayFromSunday) => {
  let result = indexFromSunday + 1 - indexFirstWeekDayFromSunday;
  result < 1 && (result = 7 + result);
  result > 7 && (result = 14 - result);
  return result;
};

export const getWeekDay = (indexFromSunday, indexFirstWeekDayFromSunday) => {
  let result = indexFirstWeekDayFromSunday + indexFromSunday;
  result > 6 && (result -= 7);
  return result;
};

export const dateToStr = (date, day = true) => {
  if (!date) return '';
  const result = [date.getFullYear(), (date.getMonth() + 1).toString().padStart(2, 0)];
  day && result.push(date.getDate().toString().padStart(2, 0));
  return result.join('-');
};

export const getWeekEdges = (date, indexFirstWeekDayFromSunday) => {
  typeof date === 'string' && (date = new Date(date));
  const weekDay = mapWeekDay(date.getDay(), indexFirstWeekDayFromSunday);
  const first = new Date(date);
  first.setDate(date.getDate() - weekDay + 1);
  const last = new Date(date);
  last.setDate(date.getDate() - weekDay + 7);
  return [dateToStr(first), dateToStr(last)];
};

export const getMonthLastDate = date => {
  typeof date === 'string' && (date = new Date(date));
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  outer.style.msOverflowStyle = 'scrollbar';
  document.body.appendChild(outer);
  const inner = document.createElement('div');
  outer.appendChild(inner);
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  outer.parentNode.removeChild(outer);
  return scrollbarWidth;
};

export const parseColor = color => {
  const div = document.createElement('div');
  div.style.position = 'absolute';
  div.style.visibility = 'hidden';
  div.style.color = color;
  document.body.append(div);
  const matches = getComputedStyle(div).color.match(
    /^rgba?\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*([\d.]+)\s*)?\)$/i,
  );
  document.body.removeChild(div);
  return {
    red: +matches[1],
    green: +matches[2],
    blue: +matches[3],
    opacity: +(matches[4] || 1),
  };
};

export const minStr = (...strings) => strings.sort()[0];
export const maxStr = (...strings) => strings.sort().reverse()[0];

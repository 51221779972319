import {createSelector} from '@reduxjs/toolkit';

export const getSelectedId = state => state.members.selected;
export const getMembersData = state => state.members.data;

export const getSelectedMembers = createSelector(
  getMembersData,
  getSelectedId,
  (members, selected) => {
    return (members || {})[selected] || [];
  },
);

export const getSubscribedMembers = createSelector(getMembersData, members => {
  const result = {};
  Object.keys(members || {}).forEach(id => {
    result[id] = members[id].filter(member => member.subscription_id);
  });
  return result;
});

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  cost: 0,
  data: null,
};

let reducers = {
  setCost: (state, action) => {
    state.cost = action.payload;
  },
  setData: (state, action) => {
    state.data = action.payload;
  },
  clearData: state => {
    state.cost = 0;
    state.data = null;
  },
};

const {actions, reducer} = createSlice({
  name: 'balance',
  initialState,
  reducers,
});

reducers = actions;
export default reducers;
export {reducer};

import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';

import {mapDate} from 'utils/mapping';
import {useForm} from 'utils/forms';
import {validateMail, validateName, validateNumber} from 'utils/validation';
import {confirmMsg, successMsg} from 'utils/messages';

import RightTemplate from 'containers/RightTemplate';
import Popup from 'containers/Popup';

import {Select, Input, Button, Spinner, Text} from 'components';

import './style.scss';

const importTemplates = {
  ru: require('assets/img/import-template_ru.png').default,
  uk: require('assets/img/import-template_uk.png').default,
  en: require('assets/img/import-template_en.png').default,
};

const formTypes = {
  hidden: 'hidden',
  add: 'add',
  edit: 'edit',
};

export default ({
  user,
  subscriptions,
  selectedId,
  members,
  onSelectMembersId,
  onAddMember,
  onUpdateMember,
  onDeleteMember,
  onSendFile,
  isLoading,
  isAdding,
  isUpdating,
  isDeleting,
  isSending,
}) => {
  const {t, i18n} = useTranslation();
  const [memberId, setMemberId] = useState(-1);
  const [formType, setFormType] = useState(formTypes.hidden);
  const isFormAdding = formType === formTypes.add;

  const addVariantsRef = useRef();
  const [addVariantsActive, setAddVariantsActive] = useState(false);
  const onPageClick = ({target}) => {
    let isCurrentComponent = target === addVariantsRef.current;
    while (target && !isCurrentComponent) {
      isCurrentComponent = target === addVariantsRef.current;
      target = target.parentNode;
    }
    !isCurrentComponent && setAddVariantsActive(false);
  };
  useEffect(() => {
    if (!addVariantsActive) return;
    window.addEventListener('mousedown', onPageClick, false);
    return () => window.removeEventListener('mousedown', onPageClick, false);
  }, [addVariantsActive]);

  const selectedIndex =
    subscriptions?.findIndex(subscription => subscription.id === selectedId) || 0;
  const selectIndex = index => onSelectMembersId(subscriptions[index].id);

  const selectedSubscription = subscriptions[selectedIndex];
  const placesCount = selectedSubscription?.count || 0;
  const subscribersCount = selectedSubscription?.subscribers_count || 0;
  const isSubscribersFull = subscribersCount >= placesCount;

  const hideForm = () => {
    setMemberId(-1);
    setFormType(formTypes.hidden);
  };

  const submitForm = async data => {
    const result = await (isFormAdding
      ? onAddMember(data)
      : onUpdateMember({id: memberId, ...data}));
    result && hideForm();
  };
  const {methods, submit, reset} = useForm({
    validation: {
      name: validateName,
      email: validateMail,
    },
    action: submitForm,
  });

  const deleteMember = async () => {
    if (!confirmMsg(t('members.messages.confirmations.remove'))) return;
    const result = await onDeleteMember(memberId);
    result && hideForm();
  };

  const showAddForm = () => {
    setAddVariantsActive(false);
    reset({name: '', email: ''});
    setFormType(formTypes.add);
  };

  const selectMember = id => {
    if (formType === formTypes.add || id === memberId) return;
    setMemberId(id);
    const selectedMember = members.find(member => member.id === id);
    reset({
      name: selectedMember.name,
      email: selectedMember.email,
    });
    setFormType(formTypes.edit);
  };

  const isSelectedMemberSubscribed = !!members.find(member => member.id === memberId)
    ?.subscription_id;

  const fileRef = useRef();
  const selectFile = () => {
    setAddVariantsActive(false);
    setFormType(formTypes.hidden);
    fileRef.current.click();
  };
  const onSelectFile = async event => {
    const count = await onSendFile(event.target.files[0]);
    if (!+count) successMsg(t('members.messages.info.fileEmpty'));
    else if (validateNumber(count))
      successMsg(t('members.messages.info.fileCount', {count}));
    fileRef.current.value = '';
  };

  const [search, setSearch] = useState('');
  let filteredMembers = [...members];
  const preparedSearch = search.trim().toLowerCase();
  if (preparedSearch !== '') {
    filteredMembers = filteredMembers.filter(
      member =>
        member.name.toLowerCase().includes(preparedSearch) ||
        member.email.toLowerCase().includes(preparedSearch),
    );
  }
  filteredMembers.sort((a, b) => (a.name < b.name ? -1 : 1));

  const [showImportHelp, setShowImportHelp] = useState(false);

  return (
    <RightTemplate title={t('members.title')}>
      <div className="column-block">
        <div className="company-name">
          {t('members.company')} <span>{user.company.name}</span>
          {subscriptions.length > 1 && (
            <>
              <div className="members-subscriptions-container">
                {t('members.subscription')}
                <Select
                  options={subscriptions.map((item, index) => ({
                    id: index,
                    text: `${mapDate(item.end_date)} / ${item.count} ${t(
                      'members.people',
                    )}`,
                  }))}
                  disabled={isAdding || isSending || formType !== formTypes.hidden}
                  value={selectedIndex}
                  onChange={selectIndex}
                />
              </div>
              {t('members.subscribed')} <span>{subscribersCount}</span>
            </>
          )}
        </div>
        <div className="left-column">
          <div className="block-content">
            {!subscriptions.length ? (
              <div>
                <Text text={t('members.empty')} />{' '}
                <Link to="/balance">{t('members.balance')}</Link>
              </div>
            ) : isLoading ? (
              <div className="members-loading">
                <Spinner size={40} />
              </div>
            ) : (
              <div className="inner">
                <div className="top">
                  <div className="name members-list-title">
                    {t('members.list.title')}
                    <Button
                      icon={require('assets/img/icon__add.png').default}
                      disabled={
                        isAdding || formType !== formTypes.hidden || isSubscribersFull
                      }
                      loading={isSending}
                      className="members-add-btn"
                      onClick={() => setAddVariantsActive(true)}
                    />
                    <div
                      className={classNames('members-add-variants', {
                        active: addVariantsActive,
                      })}
                      ref={addVariantsRef}>
                      <Button
                        invert
                        title={t('members.list.add.one')}
                        onClick={showAddForm}
                      />
                      <div className="members-add-file-container">
                        <Button
                          invert
                          title={t('members.list.add.file')}
                          onClick={selectFile}
                        />
                        <Button
                          invert
                          title="?"
                          onClick={() => setShowImportHelp(true)}
                        />
                      </div>
                      <input
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ref={fileRef}
                        onChange={onSelectFile}
                      />
                    </div>
                  </div>
                  <Input
                    className="search-member"
                    placeholder={t('members.list.find')}
                    inputClassName="search-member-input"
                    value={search}
                    icon={require('assets/img/icon__search.png').default}
                    onChange={setSearch}
                    onEscape={() => setSearch('')}
                  />
                </div>
                <div className="members-list">
                  {filteredMembers.map(member => (
                    <div
                      key={member.id}
                      className={classNames({
                        active: formType !== formTypes.add,
                        selected: memberId === member.id,
                        subscribed: !!member.subscription_id,
                      })}
                      onClick={() => selectMember(member.id)}>
                      <span>{member.name}</span>
                      {!!member.subscription_id && (
                        <img
                          src={require('assets/img/check.png').default}
                          title={t('members.list.subscribed')}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames('right-column', 'member-form', {
            active: formType !== formTypes.hidden,
          })}>
          <div className="block-content">
            <div className="name">
              {t('members.form.title')}
              {formType === formTypes.edit && !isSelectedMemberSubscribed && (
                <Button
                  loading={isDeleting}
                  icon={require('assets/img/icon__del.png').default}
                  invert
                  className="member-del-btn"
                  onClick={deleteMember}
                />
              )}
            </div>
            {isSelectedMemberSubscribed && (
              <div className="member-form-info">{t('members.form.subscribed')}</div>
            )}
            <Input
              className="member-form-input"
              placeholder={t('members.form.fields.name')}
              {...methods('name')}
            />
            <Input
              className="member-form-input"
              placeholder={t('members.form.fields.email')}
              disabled={isSelectedMemberSubscribed}
              {...methods('email')}
            />
            <div className="btn-block">
              <Button invert title={t('members.form.cancel')} onClick={hideForm} />
              <Button
                loading={isAdding || isUpdating}
                title={isFormAdding ? t('members.form.add') : t('members.form.save')}
                onClick={submit}
              />
            </div>
          </div>
        </div>
      </div>
      <Popup visible={showImportHelp} className="members-import-help">
        <div>
          <span>{t('members.list.add.help.text')}</span>
          <img src={importTemplates[i18n.language]} />
        </div>
        <Button
          title={t('members.list.add.help.submit')}
          onClick={() => setShowImportHelp(false)}
        />
      </Popup>
    </RightTemplate>
  );
};

import React, {useMemo, useEffect, useState, useRef} from 'react';
import {defaults, Doughnut} from 'react-chartjs-2';

import './style.scss';

const defaultsAnimation = defaults.animation;

export default ({width, height, values, labels, colors, onTooltipDraw}) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const [animation, setAnimation] = useState(false);
  useEffect(() => {
    if (!mounted) return;
    setAnimation({
      ...defaultsAnimation,
      onComplete: () => setAnimation(false),
    });
  }, [values]);

  const data = useMemo(
    () => ({
      labels: labels || [],
      datasets: [
        {
          data: values && [...values],
          backgroundColor: colors,
          borderWidth: 0,
        },
      ],
    }),
    [values, labels, colors],
  );

  const infoRef = useRef();
  const [info, setInfo] = useState(false);
  useEffect(() => {
    infoRef.current = info;
  }, [info]);

  const options = useMemo(
    () => ({
      animation,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: ({tooltip}) => {
            if (!tooltip.opacity) {
              setInfo(false);
              return;
            }
            const {labelColors, dataPoints, caretX: x, caretY: y} = tooltip;
            const color = labelColors[0].backgroundColor;
            const {label} = dataPoints[0];
            const value = onTooltipDraw
              ? onTooltipDraw({label, value: dataPoints[0].raw})
              : dataPoints[0].formattedValue;
            if (
              infoRef.current?.color === color &&
              infoRef.current?.label === label &&
              infoRef.current?.value === value &&
              infoRef.current?.x === x &&
              infoRef.current?.y === y
            )
              return;
            setInfo({label, value, color, x, y});
          },
        },
      },
    }),
    [animation],
  );

  return (
    <div
      style={{width, height, pointerEvents: animation && 'none'}}
      className="chart-doughnut">
      <Doughnut
        type="doughnut"
        data={data}
        options={options}
        width={width}
        height={height}
      />
      {info && (
        <div
          className="chart-doughnut-tooltip"
          style={{top: info.y + 2, left: info.y + 2}}>
          <div className="chart-doughnut-tooltip-label">{info.label}</div>
          <div className="chart-doughnut-tooltip-value">
            <div style={{backgroundColor: info.color}} />
            {info.value}
          </div>
        </div>
      )}
    </div>
  );
};

import React, {useState, useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {isLoading} from 'utils/loads';

import {getUserData} from 'store/user/selectors';
import {getMemberCost, getBalanceData} from 'store/balance/selectors';
import {loadBalance, checkPayStatus, getPayData} from 'store/balance/actions';
import {BALANCE_LOADING, GETTING_PAY_FORM} from 'store/balance/constants';

import View from './view';

const mapStateToProps = state => ({
  user: getUserData(state),
  memberCost: getMemberCost(state),
  balance: getBalanceData(state),
  isLoading: isLoading(BALANCE_LOADING),
  isGettingPayForm: isLoading(GETTING_PAY_FORM),
});

const mapActionsToProps = {
  loadBalance,
};

const Wrapper = ({loadBalance, ...props}) => {
  const history = useHistory();
  useEffect(() => {
    !props.balance && loadBalance();
    const order = /order=(\d+)/.exec(history.location.search);
    if (order) {
      checkPayStatus(order[1]);
      history.replace(history.location.pathname);
    }
  }, []);
  const [payData, setPayData] = useState(null);
  const payClick = async count => {
    const result = await getPayData(count);
    if (result) {
      setPayData(result);
    }
  };
  const formRef = useRef();
  useEffect(() => {
    if (!payData) return;
    formRef.current.submit();
  }, [payData]);
  return (
    <>
      <View onPayClick={payClick} {...props} />
      {payData && (
        <form method="POST" action={payData.url} ref={formRef} acceptCharset="utf-8">
          <input type="hidden" name="data" value={payData.data} />
          <input type="hidden" name="signature" value={payData.signature} />
        </form>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(Wrapper);

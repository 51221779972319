import React from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {getUserData} from 'store/user/selectors';

import View from './view';

const mapStateToProps = state => ({
  user: getUserData(state),
});

const mapActionsToProps = {};

const Wrapper = props => {
  const history = useHistory();
  const goToProfile = () => history.push('/profile');
  return <View onFillDataClick={goToProfile} {...props} />;
};

export default connect(mapStateToProps, mapActionsToProps)(Wrapper);

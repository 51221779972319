import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {useForm} from 'utils/forms';
import {
  isEmpty,
  validateName,
  validateMail,
  validatePassword,
  validatePhone,
} from 'utils/validation';

import RightTemplate from 'containers/RightTemplate';

import {Button, Input} from 'components';

import './style.scss';

export default ({user, onSubmit, onSignout, isLoading, onSigningout}) => {
  const {t} = useTranslation();
  const {methods, reset, submit} = useForm({
    validation: {
      name: value => validateName(value) || t('profile.errors.name'),
      email: value => validateMail(value) || t('profile.errors.email'),
      password: value => validatePassword(value, false) || t('profile.errors.password'),
      company: {
        name: value => !isEmpty(value) || t('profile.errors.company.name'),
        position: value => !isEmpty(value) || t('profile.errors.company.position'),
        phone: value => validatePhone(value) || t('profile.errors.company.phone'),
      },
    },
    action: onSubmit,
  });

  useEffect(() => reset(user), [user]);

  const fields = [
    {title: t('profile.fields.name'), key: 'name'},
    {title: t('profile.fields.email'), key: 'email'},
    {
      title: t('profile.fields.password'),
      key: 'password',
      placeholder: t('profile.placeholders.password'),
    },
    {title: t('profile.fields.company.name'), key: 'company.name'},
    {title: t('profile.fields.company.position'), key: 'company.position'},
    {title: t('profile.fields.company.phone'), key: 'company.phone'},
    {title: t('profile.fields.company.size'), key: 'company.size'},
  ];

  return (
    <RightTemplate title={t('profile.title')}>
      <Button
        title={t('profile.exit')}
        icon={require('assets/img/icon__exit.png').default}
        className="exit-button"
        invert
        loading={onSigningout}
        onClick={onSignout}
      />
      <div className="profile-form">
        {fields.map(field => (
          <div key={field.key}>
            <span>{field.title}</span>
            <Input
              className="profile-input"
              {...methods(field.key)}
              placeholder={field.placeholder}
            />
          </div>
        ))}
        <div className="buttons">
          <Button title={t('profile.cancel')} invert onClick={reset} />
          <Button title={t('profile.submit')} loading={isLoading} onClick={submit} />
        </div>
      </div>
    </RightTemplate>
  );
};

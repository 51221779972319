import React from 'react';
import {useTranslation} from 'react-i18next';

import RightTemplate from 'containers/RightTemplate';

import {Button} from 'components';

import './style.scss';

export default ({user, onFillDataClick}) => {
  const {t} = useTranslation();
  return (
    <RightTemplate title={t('welcome.title', {name: user.name})}>
      <div className="welcome-text">{t('welcome.text')}</div>
      <Button
        title={t('welcome.submit')}
        className="welcome-button"
        onClick={onFillDataClick}
      />
    </RightTemplate>
  );
};

import React, {useEffect, useState, useRef, useMemo} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

import {mapDate} from 'utils/mapping';

import CalendarComponent from './Calendar';

import './style.scss';

const {DAY, WEEK, MONTH, YEAR} = CalendarComponent.selectionTypes;

const colors = {
  weekBackground: '#4f479f',
  selectedDayBackground: 'rgba(79, 71, 159, 0.6)',
  betweenSelectedDaysBackground: 'rgba(79, 71, 159, 0.2)',
};

const Calendar = ({
  placeholder,
  className,
  disabled,
  range,
  minDate,
  maxDate,
  value,
  onChange,
  style,
}) => {
  const {t} = useTranslation();
  const {dayNames, monthNames, monthShortNames, types} = useMemo(
    () => ({
      dayNames: {
        [CalendarComponent.weekDays.SUN]: t('calendar.days.sunday'),
        [CalendarComponent.weekDays.MON]: t('calendar.days.monday'),
        [CalendarComponent.weekDays.TUE]: t('calendar.days.tuesday'),
        [CalendarComponent.weekDays.WED]: t('calendar.days.wednesday'),
        [CalendarComponent.weekDays.THU]: t('calendar.days.thursday'),
        [CalendarComponent.weekDays.FRI]: t('calendar.days.friday'),
        [CalendarComponent.weekDays.SAT]: t('calendar.days.saturday'),
      },
      monthNames: {
        1: t('calendar.months.full.january'),
        2: t('calendar.months.full.february'),
        3: t('calendar.months.full.march'),
        4: t('calendar.months.full.april'),
        5: t('calendar.months.full.may'),
        6: t('calendar.months.full.june'),
        7: t('calendar.months.full.july'),
        8: t('calendar.months.full.august'),
        9: t('calendar.months.full.september'),
        10: t('calendar.months.full.october'),
        11: t('calendar.months.full.november'),
        12: t('calendar.months.full.december'),
      },
      monthShortNames: {
        1: t('calendar.months.short.january'),
        2: t('calendar.months.short.february'),
        3: t('calendar.months.short.march'),
        4: t('calendar.months.short.april'),
        5: t('calendar.months.short.may'),
        6: t('calendar.months.short.june'),
        7: t('calendar.months.short.july'),
        8: t('calendar.months.short.august'),
        9: t('calendar.months.short.september'),
        10: t('calendar.months.short.october'),
        11: t('calendar.months.short.november'),
        12: t('calendar.months.short.december'),
      },
      types: [
        {label: t('calendar.types.days'), key: DAY},
        {label: t('calendar.types.weeks'), key: WEEK},
        {label: t('calendar.types.months'), key: MONTH},
        {label: t('calendar.types.years'), key: YEAR},
      ],
    }),
    [t],
  );

  const rootRef = useRef();
  const [focused, setFocused] = useState(false);
  const [type, setType] = useState(value?.type || DAY);

  const valueToSelection = () => {
    if (value?.from instanceof Date) {
      return {
        selected: true,
        value: value?.to instanceof Date ? [value?.from, value?.to] : value?.from,
      };
    }
    return null;
  };
  const [selection, setSelection] = useState(valueToSelection());
  const onSelect = newSelection => {
    setSelection(newSelection);
    if (newSelection.selected) {
      setFocused(false);
      onChange &&
        onChange({type, from: newSelection.value[0], to: newSelection.value[1]});
    }
  };

  const onPageClick = ({target}) => {
    let isCurrentComponent = target === rootRef.current;
    while (target && !isCurrentComponent) {
      isCurrentComponent = target === rootRef.current;
      target = target.parentNode;
    }
    if (!isCurrentComponent) {
      setFocused(false);
    }
  };
  useEffect(() => {
    if (!focused && !selection?.selected) {
      setSelection(valueToSelection());
    }
    if (focused) {
      window.addEventListener('mousedown', onPageClick, false);
      return () => window.removeEventListener('mousedown', onPageClick, false);
    }
  }, [focused]);

  const valueToCalendarValue = () => {
    if (value?.from instanceof Date) {
      return value?.to instanceof Date ? [value?.from, value?.to] : value?.from;
    }
    return null;
  };
  const [calendarValue, setCalendarValue] = useState(valueToCalendarValue());
  useEffect(() => {
    setSelection(valueToSelection());
    setCalendarValue(valueToCalendarValue());
  }, [value]);

  const onTypeClick = key => {
    setCalendarValue(null);
    setSelection(null);
    setType(key);
  };

  let text = placeholder;
  if (selection) {
    let firstValue = selection.value[0] || selection.value;
    let secondValue = selection.value[1];
    switch (type) {
      case YEAR:
        firstValue = firstValue.getFullYear();
        secondValue = secondValue.getFullYear();
        break;
      case MONTH:
        firstValue = `${firstValue.getFullYear()} ${
          monthNames[firstValue.getMonth() + 1]
        }`;
        secondValue = `${secondValue.getFullYear()} ${
          monthNames[secondValue.getMonth() + 1]
        }`;
        break;
      default:
        firstValue = mapDate(firstValue);
        secondValue = mapDate(secondValue);
    }
    if (selection.selected) {
      if (range || type === WEEK) {
        text = `${firstValue} – ${secondValue}`;
      } else {
        text = `${firstValue}`;
      }
    } else {
      text = `${firstValue} – ...`;
    }
  }

  return (
    <div
      ref={rootRef}
      className={classNames('app-calendar-container', {disabled, focused}, className)}
      style={style}
      onMouseDown={() => setFocused(true)}>
      <span className={classNames({placeholder: !selection})}>{text}</span>
      {focused && (
        <div className="calendar-popup">
          <div className="type-selector">
            {types.map(({label, key}) => (
              <div
                key={key}
                className={classNames({active: type === key})}
                onClick={() => onTypeClick(key)}>
                {label}
              </div>
            ))}
          </div>
          <div className="calendar-container">
            <CalendarComponent
              minDate={minDate}
              maxDate={maxDate}
              firstWeekDay={CalendarComponent.weekDays.MON}
              range={range}
              selectionType={type}
              value={calendarValue}
              dayNames={dayNames}
              monthNames={monthNames}
              monthShortNames={monthShortNames}
              colors={colors}
              fonts={{family: 'inherit'}}
              onSelect={onSelect}
            />
          </div>
        </div>
      )}
    </div>
  );
};

Calendar.selectionTypes = CalendarComponent.selectionTypes;

export default Calendar;

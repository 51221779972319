import {getText} from './localizator';

export const successMsg = (text = null) => {
  !text && (text = getText('messages.success'));
  alert(text);
};

export const errorMsg = (error = null, text = null) => {
  !text && (text = getText('messages.error'));
  error && console.log(error);
  alert(text);
};

export const confirmMsg = question => window.confirm(question);

import React, {useEffect, useMemo} from 'react';

import {mapWeekDay, getWeekDay, getMonthLastDate, dateToStr} from './utils';
import {
  selectionTypes,
  weekDays,
  scrollBarWidth,
  defaultProps,
  defaultColors,
  defaultFonts,
  defaultDayNames,
  defaultMonthNames,
  defaultMonthShortNames,
} from './constants';

import Days from './days/index';
import Months from './months/index';
import Years from './years/index';

import './style.scss';

const Calendar = ({
  reference,
  minDate,
  maxDate,
  range,
  firstWeekDay = defaultProps.firstWeekDay,
  weekHeight = defaultProps.weekHeight,
  dayHeight = defaultProps.dayHeight,
  monthHeight = defaultProps.monthHeight,
  yearWidth = defaultProps.yearWidth,
  yearHeight = defaultProps.yearHeight,
  selectionType = defaultProps.selectionType,
  colors = {},
  fonts = {},
  dayNames = {},
  monthNames = {},
  monthShortNames = {},
  value,
  onSelect,
  onHover,
}) => {
  colors = {...defaultColors, ...colors};
  fonts = {...defaultFonts, ...fonts};
  dayNames = {...defaultDayNames, ...dayNames};
  monthNames = {...defaultMonthNames, ...monthNames};
  monthShortNames = {...defaultMonthShortNames, ...monthShortNames};

  let valueFromStr = '';
  let valueToStr = '';
  if (value instanceof Date) {
    valueFromStr = dateToStr(value);
    valueToStr = dateToStr(value);
  } else if (value instanceof Array && value.length === 2) {
    valueFromStr = dateToStr(value[0]);
    valueToStr = dateToStr(value[1]);
  }

  minDate = new Date(minDate);
  if (Number.isNaN(minDate.getTime())) {
    minDate = new Date();
    minDate.setMonth(0);
    minDate.setDate(1);
  }
  maxDate = new Date(maxDate);
  if (Number.isNaN(maxDate.getTime())) {
    maxDate = new Date();
    maxDate.setMonth(0);
    maxDate.setDate(1);
  }

  const minDateStr = dateToStr(minDate);
  const maxDateStr = dateToStr(maxDate);

  const data = useMemo(() => {
    const result = [];
    if (selectionType === selectionTypes.MONTH) {
      const minYear = minDate.getFullYear();
      const maxYear = maxDate.getFullYear();
      let top = 0;
      // eslint-disable-next-line no-plusplus
      for (let year = minYear; year <= maxYear; year++) {
        const height = dayHeight * 2;
        result.push({
          year,
          top,
          height,
        });
        top += height;
      }
    } else if (selectionType === selectionTypes.YEAR) {
      const minYear = minDate.getFullYear();
      const maxYear = maxDate.getFullYear();
      let top = 0;
      let i = 0;
      // eslint-disable-next-line no-plusplus
      for (let year = minYear; year <= maxYear; year++) {
        // eslint-disable-next-line no-plusplus
        i++;
        result.push({
          year,
          top,
        });
        if (i % 3 === 0) top += yearHeight;
      }
    } else {
      const currentMonth = new Date(minDate);
      currentMonth.setDate(1);
      let fullHeight =
        mapWeekDay(currentMonth.getDay(), firstWeekDay) === 1 ? 0 : dayHeight;
      while (currentMonth <= maxDate) {
        const lastDate = getMonthLastDate(currentMonth);
        const firstDay = mapWeekDay(currentMonth.getDay(), firstWeekDay);
        const days = lastDate.getDate();
        const lines = Math.ceil((days + firstDay - 1) / 7);
        result.push({
          year: currentMonth.getFullYear(),
          month: currentMonth.getMonth() + 1,
          days,
          lines,
          firstDay,
          lastDay: mapWeekDay(lastDate.getDay(), firstWeekDay),
          top: fullHeight + (firstDay === 1 ? 0 : -dayHeight),
          height: lines * dayHeight,
        });
        fullHeight += lines * dayHeight - (firstDay === 1 ? 0 : dayHeight);
        currentMonth.setMonth(currentMonth.getMonth() + 1);
      }
      result[0]?.firstDay > 1 && (fullHeight += dayHeight);
    }
    return result;
  }, [
    minDateStr,
    maxDateStr,
    firstWeekDay,
    dayHeight,
    monthHeight,
    yearHeight,
    selectionType,
  ]);

  const today = new Date();
  const todayStr = dateToStr(today);

  const rootStyle = {
    fontFamily: fonts.family,
    backgroundColor: colors.background,
  };

  const weekNamesStyle = {
    height: weekHeight,
    paddingRight: scrollBarWidth,
    backgroundColor: colors.weekBackground,
    color: colors.weekText,
    fontSize: fonts.daysWeek,
  };

  return (
    <div className="calendar" style={rootStyle}>
      <div className="header">
        {[selectionTypes.DAY, selectionTypes.WEEK].includes(selectionType) && (
          <div className="week" style={weekNamesStyle}>
            {[...Array(7).keys()].map(day => (
              <div key={day}>{dayNames[getWeekDay(day, firstWeekDay)]}</div>
            ))}
          </div>
        )}
      </div>
      {selectionType === selectionTypes.MONTH ? (
        <Months
          reference={reference}
          todayStr={todayStr}
          minDate={minDate}
          minDateStr={minDateStr}
          maxDate={maxDate}
          maxDateStr={maxDateStr}
          data={data}
          monthNames={monthShortNames}
          range={range}
          monthHeight={monthHeight}
          yearWidth={yearWidth}
          colors={colors}
          fonts={fonts}
          valueFromStr={valueFromStr}
          valueToStr={valueToStr}
          onSelect={onSelect}
          onHover={onHover}
        />
      ) : selectionType === selectionTypes.YEAR ? (
        <Years
          reference={reference}
          todayStr={todayStr}
          minDate={minDate}
          minDateStr={minDateStr}
          maxDate={maxDate}
          maxDateStr={maxDateStr}
          data={data}
          range={range}
          yearWidth={yearWidth}
          yearHeight={yearHeight}
          colors={colors}
          fonts={fonts}
          valueFromStr={valueFromStr}
          valueToStr={valueToStr}
          onSelect={onSelect}
          onHover={onHover}
        />
      ) : (
        <Days
          reference={reference}
          todayStr={todayStr}
          minDate={minDate}
          minDateStr={minDateStr}
          maxDate={maxDate}
          maxDateStr={maxDateStr}
          data={data}
          monthNames={monthNames}
          range={range}
          dayHeight={dayHeight}
          firstWeekDay={firstWeekDay}
          selectionType={selectionType}
          colors={colors}
          fonts={fonts}
          valueFromStr={valueFromStr}
          valueToStr={valueToStr}
          onSelect={onSelect}
          onHover={onHover}
        />
      )}
    </div>
  );
};

Calendar.weekDays = weekDays;
Calendar.selectionTypes = selectionTypes;

export default Calendar;

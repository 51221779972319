import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {isLoading} from 'utils/loads';

import {getUserData} from 'store/user/selectors';
import {
  getStatisticsData,
  getStatisticsRange,
  getStatisticsEdges,
} from 'store/statistics/selectors';
import {loadEdges, setRange, loadStatistics} from 'store/statistics/actions';
import {STATISTICS_LOADING, STATISTICS_EDGES_LOADING} from 'store/statistics/constants';

import View, {Extra} from './view';
import Calendar from 'components/Calendar';

const mapStateToProps = state => ({
  user: getUserData(state),
  edges: getStatisticsEdges(state),
  range: getStatisticsRange(state),
  data: getStatisticsData(state),
  isLoading: isLoading(STATISTICS_LOADING, STATISTICS_EDGES_LOADING),
});

const mapActionsToProps = {
  loadEdges,
  loadStatistics,
  setRange,
};

let language;

const Wrapper = props => {
  const {range, loadEdges, loadStatistics, setRange} = props;
  const onChangeRange = newRange => {
    setRange(newRange);
    loadStatistics(newRange);
  };
  useEffect(() => {
    if (!range) {
      loadEdges();
      const today = new Date();
      today.getDate() < 5 && today.setMonth(today.getMonth() - 1);
      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      onChangeRange({type: Calendar.selectionTypes.DAY, from: firstDay, to: lastDay});
    }
  }, []);
  const {i18n} = useTranslation();
  useEffect(() => {
    if (language !== i18n.language) {
      language = i18n.language;
      range && loadStatistics(range);
    }
  }, [i18n.language]);
  return <View onChangeRange={onChangeRange} {...props} />;
};

Wrapper.extra = Extra;

export default connect(mapStateToProps, mapActionsToProps)(Wrapper);

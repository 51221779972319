import React from 'react';
import {maxStr, minStr} from '../utils';
import {selectionStatuses} from '../constants';

export default ({
  year,
  current,
  hoveredYear,
  selectedYears,
  selectionStatus,
  yearHeight,
  yearWidth,
  colors,
  fonts,
  onYearClick,
  onYearHover,
}) => {
  const {
    yearsYearText,
    todayBorder,
    hoveredDayBackground,
    selectedHoveredDayBackground,
    selectedDayBackground,
    selectedDayText,
    betweenSelectedDaysBackground,
  } = colors;

  const isCurrent = current === year;
  const isHovered = selectionStatus !== selectionStatuses.FIRST && year === hoveredYear;
  const years =
    selectionStatus === selectionStatuses.FIRST
      ? [hoveredYear, ...selectedYears]
      : selectedYears;
  const minYear = minStr(...years);
  const maxYear = maxStr(...years);
  const isBetween = year >= minYear && year <= maxYear;
  const isFirstInRange = year === minYear;
  const isLastInRange = year === maxYear;

  const fitSize = Math.min(yearHeight, yearWidth);
  const selectedRadius = fitSize / 2;

  const yearStyle = {
    height: yearHeight,
  };

  const firstTextWrapperStyle = {};
  if (isHovered) {
    firstTextWrapperStyle.backgroundColor = hoveredDayBackground;
  } else if (isBetween) {
    firstTextWrapperStyle.backgroundColor = betweenSelectedDaysBackground;
  }
  if (isFirstInRange || (!isBetween && isHovered)) {
    firstTextWrapperStyle.borderTopLeftRadius = selectedRadius;
    firstTextWrapperStyle.borderBottomLeftRadius = selectedRadius;
  }
  if (isLastInRange || (!isBetween && isHovered)) {
    firstTextWrapperStyle.borderTopRightRadius = selectedRadius;
    firstTextWrapperStyle.borderBottomRightRadius = selectedRadius;
  }

  const secondTextWrapperStyle = {
    borderRadius: selectedRadius,
    fontSize: fonts.yearsYear,
  };
  if (isFirstInRange || isLastInRange) {
    secondTextWrapperStyle.color = selectedDayText;
    if (isHovered) {
      secondTextWrapperStyle.backgroundColor = selectedHoveredDayBackground;
    } else {
      secondTextWrapperStyle.backgroundColor = selectedDayBackground;
    }
  } else {
    secondTextWrapperStyle.color = yearsYearText;
  }

  const currentStyle = {
    width: fitSize - 8,
    height: fitSize - 8,
    marginLeft: (fitSize - 8) / -2,
    marginTop: (fitSize - 8) / -2,
    borderColor: todayBorder,
  };

  return (
    <div style={yearStyle} onClick={onYearClick} onMouseEnter={() => onYearHover(year)}>
      <div style={firstTextWrapperStyle}>
        <div style={secondTextWrapperStyle}>{year}</div>
      </div>
      {isCurrent && <div className="current" style={currentStyle} />}
    </div>
  );
};

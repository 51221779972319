import React from 'react';
import classNames from 'classnames';

import Spinner from '../Spinner';

import './style.scss';

const Button = ({title, icon, className, disabled, loading, invert, onClick, style}) => {
  const handleClick = () => {
    if (disabled || loading || !onClick) return;
    onClick();
  };
  return (
    <button
      disabled={disabled || loading}
      className={classNames(
        'app-button',
        {invert, loading, 'button-title': !!title, 'button-icon': !!icon, disabled},
        className,
      )}
      style={style}
      onClick={handleClick}>
      {icon && <img src={icon} className={classNames({transparent: loading})} />}
      <span className={classNames({transparent: loading})}>{title}</span>
      <div className="spinner">
        <Spinner invert={!invert} size={20} stroke={7} />
      </div>
    </button>
  );
};

export default Button;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {getWeekEdges, maxStr, minStr, parseColor} from '../utils';
import {selectionStatuses, selectionTypes} from '../constants';

import Day from './day';

import './style.scss';

export default ({
  reference,
  todayStr,
  minDateStr,
  maxDateStr,
  data,
  monthNames,
  range,
  dayHeight,
  firstWeekDay,
  selectionType,
  colors,
  fonts,
  valueFromStr,
  valueToStr,
  onSelect,
  onHover,
}) => {
  const {monthName, yearName, monthBackgroundTop, monthBackgroundBottom} = colors;
  const gradients = useMemo(() => {
    const top = parseColor(monthBackgroundTop);
    const bottom = parseColor(monthBackgroundBottom);
    const results = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 7; i++) {
      const result = [];
      ['red', 'green', 'blue', 'opacity'].forEach(key => {
        let item = top[key] + ((bottom[key] - top[key]) / 6) * i;
        key !== 'opacity' && (item = Math.round(item));
        result.push(item);
      });
      results.push(`rgba(${result.join(',')})`);
    }
    return [
      `linear-gradient(${results[0]}, ${results[1]})`,
      `linear-gradient(${results[1]}, ${results[2]})`,
      `linear-gradient(${results[2]}, ${results[3]})`,
      `linear-gradient(${results[3]}, ${results[4]})`,
      `linear-gradient(${results[4]}, ${results[5]})`,
      `linear-gradient(${results[5]}, ${results[6]})`,
    ];
  }, [monthBackgroundTop, monthBackgroundBottom]);

  const daysScrollRef = useRef();
  const [daysScrollTop, setDaysScrollTop] = useState(0);
  const onDaysScroll = ({target}) => setDaysScrollTop(target.scrollTop);
  const daysScrollHeight = daysScrollRef.current?.offsetHeight;

  const scrollToValue = () => {
    let dayToScroll = todayStr;
    if (valueFromStr >= minDateStr && valueFromStr <= maxDateStr) {
      dayToScroll = valueFromStr;
    }
    if (dayToScroll >= minDateStr && dayToScroll <= maxDateStr) {
      dayToScroll = new Date(dayToScroll);
      const index = data.findIndex(
        ({year, month}) => dayToScroll < new Date(year, month, 1),
      );
      if (index > 0) {
        daysScrollRef.current.scrollTop = data[index].top - dayHeight;
      }
    }
  };
  useEffect(() => {
    scrollToValue();
  }, []);
  reference && (reference.current = {scrollToValue});

  const daysRef = useRef();
  const [dayWidth, setDayWidth] = useState(0);
  const updateDayWidth = () => setDayWidth(daysRef.current.clientWidth / 7);
  useEffect(() => {
    updateDayWidth();
    window.addEventListener('resize', updateDayWidth);
    return () => window.removeEventListener('resize', updateDayWidth);
  }, []);

  const [status, setStatus] = useState(selectionStatuses.CLEAR);
  const [selectedDates, setSelectedDates] = useState([]);
  const [hoveredDates, setHoveredDates] = useState([]);
  const onDayClick = () => {
    // eslint-disable-next-line one-var
    let newSatus, newSelectedDates;
    // eslint-disable-next-line default-case
    switch (status) {
      case selectionStatuses.CLEAR:
      case selectionStatuses.FULL:
        newSatus = range ? selectionStatuses.FIRST : selectionStatuses.FULL;
        newSelectedDates = hoveredDates;
        break;
      case selectionStatuses.FIRST:
        // eslint-disable-next-line no-case-declarations
        const dates = [...hoveredDates, ...selectedDates];
        newSatus = selectionStatuses.FULL;
        newSelectedDates = [minStr(...dates), maxStr(...dates)];
        break;
    }
    setStatus(newSatus);
    setSelectedDates(newSelectedDates);
    if (!onSelect) return;
    onSelect({
      selected: newSatus === selectionStatuses.FULL,
      value:
        range || selectionType !== selectionTypes.DAY
          ? [new Date(newSelectedDates[0]), new Date(newSelectedDates[1])]
          : new Date(newSelectedDates[0]),
    });
  };
  const onDayHover = date => {
    let dates;
    if (selectionType === selectionTypes.WEEK) {
      dates = getWeekEdges(date, firstWeekDay);
    } else {
      dates = [date, date];
    }
    setHoveredDates(dates);
  };
  const onDaysLeave = () => status !== selectionStatuses.FIRST && setHoveredDates([]);

  useEffect(() => {
    if (!onHover) return;
    onHover(
      !hoveredDates.length
        ? null
        : hoveredDates[0] !== hoveredDates[1]
        ? [new Date(hoveredDates[0]), new Date(hoveredDates[1])]
        : new Date(hoveredDates[0]),
    );
  }, [hoveredDates]);

  useEffect(() => {
    if (!valueFromStr) {
      setSelectedDates([]);
      setStatus(selectionStatuses.CLEAR);
    } else {
      setSelectedDates([valueFromStr, valueToStr]);
      setStatus(selectionStatuses.FULL);
    }
  }, [valueToStr, valueFromStr]);

  const lastMonth = data[data.length - 1];
  const fullHeight = lastMonth ? lastMonth.top + lastMonth.height : 0;

  const daysStyle = {
    height: fullHeight,
  };

  return (
    <div ref={daysScrollRef} className="days-scroll" onScroll={onDaysScroll}>
      <div ref={daysRef} className="days" style={daysStyle} onMouseLeave={onDaysLeave}>
        {data.map((item, index) => {
          const {year, month, days, firstDay, lastDay, top, height} = item;
          if (
            !daysScrollHeight ||
            top < daysScrollTop - height ||
            top > daysScrollTop + daysScrollHeight
          )
            return false;
          const isLastMonth = index === data.length - 1;
          const monthStr = `${year}-${month.toString().padStart(2, 0)}`;
          const monthNameTop = firstDay === 1 ? 0 : dayHeight;
          const monthNameBottom = lastDay === 7 ? 0 : dayHeight;
          const monthStyle = {
            color: monthName,
            fontSize: fonts.daysMonth,
          };
          const yearStyle = {
            color: yearName,
            fontSize: fonts.daysYear,
            marginBottom: -fonts.daysYear / 2,
          };
          return (
            <div key={monthStr} className="month" style={{top}}>
              {Array.from({length: days}, (x, i) => i + 1).map(day => (
                <Day
                  key={monthStr + day}
                  day={day}
                  days={days}
                  dayHeight={dayHeight}
                  dayWidth={dayWidth}
                  firstDay={firstDay}
                  hoveredDates={hoveredDates}
                  selectedDates={selectedDates}
                  isLastMonth={isLastMonth}
                  monthStr={monthStr}
                  selectionStatus={status}
                  todayStr={todayStr}
                  gradients={gradients}
                  colors={colors}
                  fonts={fonts}
                  onDayHover={onDayHover}
                  onDayClick={onDayClick}
                />
              ))}
              <div
                className="month-name"
                style={{top: monthNameTop, bottom: monthNameBottom}}>
                <div className="year" style={yearStyle}>
                  {year}
                </div>
                <div className="month" style={monthStyle}>
                  {monthNames[month]}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

import React, {useMemo, useCallback, useEffect, useState} from 'react';
import {defaults, Line} from 'react-chartjs-2';

import {tooltipLine} from './plugins';

const defaultsAnimation = defaults.animation;

export default ({
  width,
  height,
  values,
  labels,
  min,
  max,
  step,
  onTickDraw,
  onTooltipDraw,
}) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const [animation, setAnimation] = useState(false);
  useEffect(() => {
    if (!mounted) return;
    setAnimation({
      ...defaultsAnimation,
      onComplete: () => setAnimation(false),
    });
  }, [values]);

  const data = useCallback(
    canvas => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.offsetHeight);
      gradient.addColorStop(0, '#4C41CE');
      gradient.addColorStop(1, 'rgba(249, 116, 104, 0)');
      return {
        labels,
        datasets: [
          {
            data: values && [...values],
            backgroundColor: gradient,
            fill: true,
            borderColor: ['#4C41CE'],
            borderWidth: 2,
            pointBackgroundColor: '#FFC200',
            pointBorderColor: '#F6F5FA',
            pointRadius: 0,
            pointHoverRadius: 5,
            pointHitRadius: 5,
            tension: 0.35,
          },
        ],
      };
    },
    [values, labels],
  );

  const options = useMemo(
    () => ({
      responsive: true,
      animation,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          position: 'average',
          backgroundColor: '#fff',
          titleColor: '#696871',
          titleFont: {
            size: 10,
            family: '"Montserrat", sans-serif',
            style: 'normal',
          },
          bodyColor: '#696871',
          bodyFont: {
            size: 10,
            family: '"Montserrat", sans-serif',
            style: 'normal',
          },
          cornerRadius: 5,
          padding: 12,
          callbacks: {
            label: ({label, raw, formattedValue, datasetIndex}) => {
              onTooltipDraw &&
                (formattedValue = onTooltipDraw({
                  label,
                  value: raw,
                  index: datasetIndex,
                }));
              return formattedValue;
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: '"Montserrat", sans-serif',
              size: 10,
            },
          },
        },
        y: {
          grid: {
            display: true,
          },
          min,
          max,
          ticks: {
            stepSize: step,
            font: {
              family: '"Montserrat", sans-serif',
              size: 10,
            },
            callback: onTickDraw,
          },
        },
      },
    }),
    [min, max, step, onTickDraw, onTooltipDraw, animation],
  );

  return (
    <Line
      type="line"
      redraw={false}
      data={data}
      options={options}
      plugins={[tooltipLine]}
      width={width}
      height={height}
    />
  );
};

import React from 'react';
import classNames from 'classnames';

import './style.scss';

const mapKey = key => key || (key === 0 ? '0' : null);

const Select = ({className, disabled, options = [], value, onChange, style}) => {
  return (
    <select
      className={classNames('app-select', className)}
      value={value}
      disabled={disabled}
      style={style}
      onChange={event => onChange && onChange(event.target.value)}>
      {options.map((option, index) => {
        typeof option !== 'object' && (option = {id: option});
        const value =
          mapKey(option.id) || mapKey(option.key) || mapKey(option.value) || option.val;
        const text = option.text || option.title || option.name || value;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <option key={index} value={value}>
            {text}
          </option>
        );
      })}
    </select>
  );
};

export default Select;

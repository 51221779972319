import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {init} from 'store/app/actions';
import {getInited, getAuthorized} from 'store/app/selectors';
import {isCompany} from 'store/user/selectors';

import View from './view';

const mapStateToProps = state => ({
  isInited: getInited(state),
  isAuthorized: getAuthorized(state),
  isCompany: isCompany(state),
});

const mapActionsToProps = {
  init,
};

const Wrapper = ({init, ...props}) => {
  useEffect(init, []);
  return <View {...props} />;
};

export default connect(mapStateToProps, mapActionsToProps)(Wrapper);

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isInited: false,
  isAuthorized: false,
};

let reducers = {
  setInited: (state, action) => {
    state.isInited = action.payload;
  },
  setAuthorized: (state, action) => {
    state.isAuthorized = action.payload;
  },
};

const {actions, reducer} = createSlice({
  name: 'app',
  initialState,
  reducers,
});

reducers = actions;
export default reducers;
export {reducer};

import api from 'services/api';
import {startLoading, finishLoading} from 'utils/loads';
import {errorMsg, successMsg} from 'utils/messages';
import {apiDate} from 'utils/mapping';
import {getLanguage, getText} from '../../utils/localizator';

import reducers from './reducer';
import * as constants from './constants';

export const loadEdges = () => async dispatch => {
  try {
    startLoading(constants.STATISTICS_EDGES_LOADING);
    const edges = await api('statistics/edges').get();
    dispatch(
      reducers.setEdges([
        new Date(edges[0].substr(0, 10)),
        new Date(edges[1].substr(0, 10)),
      ]),
    );
  } finally {
    finishLoading(constants.STATISTICS_EDGES_LOADING);
  }
};

export const setRange = range => reducers.setRange(range);

const mapLineChartData = data => {
  const result = {labels: [], values: []};
  (data || []).forEach(item => {
    result.labels.push(item.label);
    result.values.push(item.value);
  });
  return result;
};

const mapDoughnutChartData = data => {
  const result = {};
  (data || []).forEach(item => {
    !result[item.type] && (result[item.type] = {labels: [], values: []});
    result[item.type].labels.push(item.label);
    result[item.type].values.push(item.value);
  });
  return result;
};

const mapMultilineChartData = data => {
  const result = {};
  Object.keys(data || {}).forEach(type => {
    const values = {};
    (data || {})[type]?.forEach(item => {
      !result[type] && (result[type] = {labels: [], values: []});
      result[type].labels.push(item.label);
      Object.keys(item.values).forEach(title => {
        !values[title] && (values[title] = []);
        values[title].push(item.values[title]);
      });
    });
    Object.keys(values).forEach(title => {
      result[type].values.push({title, data: values[title]});
    });
  });
  return result;
};

export const loadStatistics =
  ({type, from, to}) =>
  async dispatch => {
    try {
      startLoading(constants.STATISTICS_LOADING);
      const data = await api('statistics', {
        mode: type,
        from: apiDate(from),
        to: apiDate(to),
        language: getLanguage(),
      }).get();
      let {averages, totals, contexts, dynamics} = data;
      totals = {amount: totals.amount, ...mapLineChartData(totals.data)};
      averages = {amount: averages.amount, ...mapLineChartData(averages.data)};
      contexts = {
        counts: mapDoughnutChartData(contexts.counts),
        durations: mapDoughnutChartData(contexts.durations),
      };
      dynamics = {
        counts: mapMultilineChartData(dynamics.counts),
        durations: mapMultilineChartData(dynamics.durations),
      };
      dispatch(reducers.setData({averages, totals, contexts, dynamics}));
    } catch (error) {
      errorMsg(error, getText('statistics.messages.errors.load'));
    } finally {
      finishLoading(constants.STATISTICS_LOADING);
    }
  };

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import i18next from 'i18next';
import {I18nextProvider} from 'react-i18next';

import store from './store/index';
import App from './app/index';

const langs = ['ru', 'uk', 'en'];
const matches = window.location.search.match(/lang=(\w{2})/);
if (matches && langs.includes(matches[1])) {
  window.localStorage.setItem('lng', matches[1]);
  window.location.href = window.location.origin + window.location.pathname;
}
const lng =
  matches && langs.includes(matches[1])
    ? matches[1]
    : window.localStorage.lng ||
      (langs.includes(navigator.language) ? navigator.language : 'ru');
i18next.init({
  interpolation: {escapeValue: false},
  lng,
  resources: {
    ru: {translation: require('./translations/ru.json')},
    uk: {translation: require('./translations/uk.json')},
    en: {translation: require('./translations/en.json')},
  },
});
i18next.on('languageChanged', language => {
  window.localStorage.setItem('lng', language);
});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root'),
);

import {startLoading, finishLoading} from 'utils/loads';
import api from 'services/api';
import {errorMsg} from 'utils/messages';
import {getLanguage, getText} from 'utils/localizator';

import reducers from './reducer';
import * as constants from './constants';
import {getSelectedId} from './selectors';
import {getBalanceData} from 'store/balance/selectors';
import {loadBalance} from 'store/balance/actions';

export const setSelectedMembersId = reducers.setSelected;

export const loadMembers = () => async (dispatch, getState) => {
  try {
    startLoading(constants.MEMBERS_LOADING);
    !getBalanceData(getState()) && (await dispatch(loadBalance()));
    const data = await api('members').get();
    dispatch(reducers.setData(data));
  } catch (error) {
    errorMsg(error, getText('members.messages.errors.load'));
  } finally {
    finishLoading(constants.MEMBERS_LOADING);
  }
};

export const addMember =
  ({name, email}) =>
  async (dispatch, getState) => {
    try {
      startLoading(constants.MEMBERS_ADDING);
      const data = await api('members', {
        subscription: getSelectedId(getState()),
        name,
        email,
        language: getLanguage(),
      }).put();
      dispatch(reducers.setData(data));
      return true;
    } catch (error) {
      const msg = error.message === 'exist' && getText('members.messages.errors.exist');
      errorMsg(error, msg);
    } finally {
      finishLoading(constants.MEMBERS_ADDING);
    }
  };

export const updateMember =
  ({id, name, email}) =>
  async dispatch => {
    try {
      startLoading(constants.MEMBERS_UPDATING);
      const data = await api('members', {
        id,
        name,
        email,
        language: getLanguage(),
      }).patch();
      dispatch(reducers.setData(data));
      return true;
    } catch (error) {
      const msg = error.message === 'exist' && getText('members.messages.errors.exist');
      errorMsg(error, msg);
    } finally {
      finishLoading(constants.MEMBERS_UPDATING);
    }
  };

export const deleteMember = id => async dispatch => {
  try {
    startLoading(constants.MEMBERS_DELETING);
    const data = await api('members', {id}).delete();
    dispatch(reducers.setData(data));
    return true;
  } catch (error) {
    errorMsg(error);
  } finally {
    finishLoading(constants.MEMBERS_DELETING);
  }
};

export const sendFile = file => async (dispatch, getState) => {
  try {
    startLoading(constants.MEMBERS_SENDING_FILE);
    const {count, data} = await api('members', {
      subscription: getSelectedId(getState()),
      file,
      language: getLanguage(),
    }).post();
    dispatch(reducers.setData(data));
    return count;
  } catch (error) {
    errorMsg(error);
  } finally {
    finishLoading(constants.MEMBERS_SENDING_FILE);
  }
};
